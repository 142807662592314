import * as Sentry from '@sentry/react';

type ErrorContext = Record<string, any>;

// Simple error logging for development
const logError = (error: Error, context?: ErrorContext) => {
    console.error('Error:', error);
    if (context) {
        console.error('Context:', context);
    }
};

// Production error tracking
const initErrorTracking = () => {
    if (process.env.NODE_ENV === 'production') {
        try {
            Sentry.init({
                dsn: import.meta.env.VITE_SENTRY_DSN,
                environment: process.env.NODE_ENV,
                tracesSampleRate: 1.0,
            });
        } catch (error) {
            console.error('Failed to initialize error tracking:', error);
        }
    }
};

export { initErrorTracking, logError }; 