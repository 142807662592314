type EventType = 'verse_lookup' | 'verse_share' | 'verse_copy';

export const trackEvent = (eventName: EventType, properties?: Record<string, any>) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: eventName,
            ...properties
        });
    }
};

export const initAnalytics = () => {
    window.dataLayer = window.dataLayer || [];
}; 