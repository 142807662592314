import React, { useState } from 'react';
import { BookOpen, Copy, Share2, Twitter, Facebook, Linkedin, Instagram, MessageCircle, X } from 'lucide-react';
import { BirthVerse } from '../types/verses';

interface VerseCardProps {
  verse: BirthVerse;
  date: Date;
}

const VerseCard: React.FC<VerseCardProps> = ({ verse, date }) => {
  const [copied, setCopied] = useState(false);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [showInstructions, setShowInstructions] = useState<string | null>(null);

  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric'
  });

  const shareText = `My BirthVerse (${formattedDate}): "${verse.text}" - ${verse.reference}\n\nFind your BirthVerse at https://birthverse.com ✨`;

  const platforms = [
    {
      name: 'Twitter',
      icon: Twitter,
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`
    },
    {
      name: 'Facebook',
      icon: Facebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://birthverse.com')}&quote=${encodeURIComponent(shareText)}`
    },
    {
      name: 'LinkedIn',
      icon: Linkedin,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent('https://birthverse.com')}&summary=${encodeURIComponent(shareText)}`
    },
    {
      name: 'Reddit',
      icon: MessageCircle,
      url: `https://www.reddit.com/submit?url=${encodeURIComponent('https://birthverse.com')}&title=${encodeURIComponent(shareText)}`
    },
    {
      name: 'Instagram',
      icon: Instagram,
      instructions: [
        '1. Copy the verse (automatically copied)',
        '2. Open Instagram app',
        '3. Create a new Story or Post',
        '4. Paste the verse in your Story or Bio',
        'Tip: Add a screenshot of your BirthVerse for more impact!'
      ]
    }
  ];

  const handleShare = async (platform: typeof platforms[0]) => {
    if (platform.instructions) {
      await navigator.clipboard.writeText(shareText);
      setShowInstructions(platform.name);
      return;
    }

    if (platform.url) {
      const width = 550;
      const height = 400;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      window.open(
        platform.url,
        `Share on ${platform.name}`,
        `width=${width},height=${height},left=${left},top=${top},popup=yes`
      );
      setShowShareMenu(false);
    }
  };

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(shareText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    setShowShareMenu(false);
  };

  return (
    <div className="bg-white rounded-2xl shadow-sm">
      <div className="p-4 sm:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 sm:mb-6 space-y-3 sm:space-y-0">
          <div className="flex items-center space-x-3">
            <div className="bg-[#F5F3FF] p-2 sm:p-2.5 rounded-xl">
              <BookOpen className="h-5 w-5 sm:h-6 sm:w-6 text-[#4F46E5]" />
            </div>
            <h3 className="text-lg sm:text-xl font-semibold text-gray-900">
              Your BirthVerse for {formattedDate}
            </h3>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handleCopyClick}
              className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-50 transition-colors relative"
              title={copied ? 'Copied!' : 'Copy verse'}
            >
              <Copy className="h-5 w-5" />
              {copied && (
                <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-xs py-1 px-2 rounded whitespace-nowrap">
                  Copied!
                </span>
              )}
            </button>
            <div className="relative">
              <button
                onClick={() => setShowShareMenu(!showShareMenu)}
                className="p-2 text-gray-400 hover:text-[#4F46E5] rounded-full hover:bg-gray-50 transition-colors"
                title="Share verse"
              >
                <Share2 className="h-5 w-5" />
              </button>

              {showShareMenu && (
                <div className="fixed sm:absolute z-50 bottom-0 sm:bottom-auto left-0 sm:left-auto right-0 sm:right-0 sm:mt-2 bg-white rounded-t-xl sm:rounded-xl shadow-xl border border-gray-100 p-2 min-w-[240px] sm:min-w-[280px] sm:transform sm:translate-x-0 sm:-translate-y-2">
                  <div className="relative">
                    <div className="flex justify-between items-center px-3 py-2 border-b border-gray-100">
                      <span className="text-sm font-medium text-gray-700">Share via</span>
                      <button
                        onClick={() => {
                          setShowShareMenu(false);
                          setShowInstructions(null);
                        }}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                    {showInstructions ? (
                      <div className="p-4">
                        <h4 className="font-medium text-gray-900 mb-3">Share on {showInstructions}</h4>
                        <ul className="space-y-2 text-sm text-gray-600">
                          {platforms.find(p => p.name === showInstructions)?.instructions?.map((instruction, i) => (
                            <li key={i}>{instruction}</li>
                          ))}
                        </ul>
                        <button
                          onClick={() => setShowInstructions(null)}
                          className="mt-4 w-full px-4 py-2 text-sm text-[#4F46E5] hover:bg-[#4F46E5] hover:text-white rounded-md transition-colors"
                        >
                          Back to sharing options
                        </button>
                      </div>
                    ) : (
                      <div className="py-1">
                        {platforms.map((platform) => (
                          <button
                            key={platform.name}
                            onClick={() => handleShare(platform)}
                            className="flex items-center space-x-3 w-full px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md transition-colors"
                          >
                            <platform.icon className="h-4 w-4" />
                            <span>Share on {platform.name}</span>
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <blockquote className="text-lg sm:text-xl text-gray-700 mb-4 sm:mb-6 relative pl-4 sm:pl-6">
          <span className="absolute left-0 top-0 text-3xl sm:text-4xl text-[#4F46E5] opacity-20 leading-none">"</span>
          {verse.text}
          <span className="absolute right-0 bottom-0 text-3xl sm:text-4xl text-[#4F46E5] opacity-20 leading-none">"</span>
        </blockquote>

        <div className="flex flex-col sm:flex-row sm:items-center justify-between text-sm space-y-2 sm:space-y-0">
          <cite className="text-[#4F46E5] font-semibold not-italic text-base sm:text-lg">
            {verse.reference}
          </cite>
          <span className="text-gray-500">
            Chapter {verse.chapter}, Verse {verse.verse}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VerseCard;