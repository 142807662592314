import { BirthVerse } from '../../types/verses';

export const octoberVerses: BirthVerse[] = [
  {
    date: "10-01",
    text: "Ask the LORD for rain in the springtime; it is the LORD who makes the storm clouds. He gives showers of rain to men, and plants of the field to everyone.",
    reference: "Zechariah 10:1",
    book: "Zechariah",
    chapter: 10,
    verse: 1
  },
  {
    date: "10-02",
    text: "I will write on the tablets the words that were on the first tablets, which you broke. Then you are to put them in the chest.",
    reference: "Deuteronomy 10:2",
    book: "Deuteronomy",
    chapter: 10,
    verse: 2
  },
  {
    date: "10-03",
    text: "The watchman opens the gate for him, and the sheep listen to his voice. He calls his own sheep by name and leads them out.",
    reference: "John 10:3",
    book: "John",
    chapter: 10,
    verse: 3
  },
  {
    date: "10-04",
    text: "Christ is the end of the law so that there may be righteousness for everyone who believes.",
    reference: "Romans 10:4",
    book: "Romans",
    chapter: 10,
    verse: 4
  },
  {
    date: "10-05",
    text: "We demolish arguments and every pretension that sets itself up against the knowledge of God, and we take captive every thought to make it obedient to Christ.",
    reference: "2 Corinthians 10:5",
    book: "2 Corinthians",
    chapter: 10,
    verse: 5
  },
  {
    date: "10-06",
    text: "No one is like you, O LORD; you are great, and your name is mighty in power.",
    reference: "Jeremiah 10:6",
    book: "Jeremiah",
    chapter: 10,
    verse: 6
  },
  {
    date: "10-07",
    text: "As you go, preach this message: The kingdom of heaven is near.",
    reference: "Matthew 10:7",
    book: "Matthew",
    chapter: 10,
    verse: 7
  },
  {
    date: "10-08",
    text: "But what does it say? The word is near you; it is in your mouth and in your heart, that is, the word of faith we are proclaiming.",
    reference: "Romans 10:8",
    book: "Romans",
    chapter: 10,
    verse: 8
  },
  {
    date: "10-09",
    text: "I am the gate; whoever enters through me will be saved. He will come in and go out, and find pasture.",
    reference: "John 10:9",
    book: "John",
    chapter: 10,
    verse: 9
  },
  {
    date: "10-10",
    text: "For it is with your heart that you believe and are justified, and it is with your mouth that you confess and are saved.",
    reference: "Romans 10:10",
    book: "Romans",
    chapter: 10,
    verse: 10
  },
  {
    date: "10-11",
    text: "As the Scripture says, Anyone who trusts in him will never be put to shame.",
    reference: "Romans 10:11",
    book: "Romans",
    chapter: 10,
    verse: 11
  },
  {
    date: "10-12",
    text: "Sow for yourselves righteousness, reap the fruit of unfailing love, and break up your unplowed ground. For it is time to seek the LORD, until he comes and showers righteousness on you.",
    reference: "Hosea 10:12",
    book: "Hosea",
    chapter: 10,
    verse: 12
  },
  {
    date: "10-13",
    text: "No temptation has seized you except what is common to man. And God is faithful; he will not let you be tempted beyond what you can bear. But when you are tempted, he will also provide a way out so that you can stand up under it.",
    reference: "1 Corinthians 10:13",
    book: "1 Corinthians",
    chapter: 10,
    verse: 13
  },
  {
    date: "10-14",
    text: "I am the good shepherd; I know my sheep and my sheep know me.",
    reference: "John 10:14",
    book: "John",
    chapter: 10,
    verse: 14
  },
  {
    date: "10-15",
    text: "Just as the Father knows me and I know the Father and I lay down my life for the sheep.",
    reference: "John 10:15",
    book: "John",
    chapter: 10,
    verse: 15
  },
  {
    date: "10-16",
    text: "This is the covenant I will make with them after that time, says the Lord. I will put my laws in their hearts, and I will write them on their minds.",
    reference: "Hebrews 10:16",
    book: "Hebrews",
    chapter: 10,
    verse: 16
  },
  {
    date: "10-17",
    text: "Consequently, faith comes from hearing the message, and the message is heard through the word of Christ.",
    reference: "Romans 10:17",
    book: "Romans",
    chapter: 10,
    verse: 17
  },
  {
    date: "10-18",
    text: "For it is not the one who commends himself who is approved, but the one whom the Lord commends.",
    reference: "2 Corinthians 10:18",
    book: "2 Corinthians",
    chapter: 10,
    verse: 18
  },
  {
    date: "10-19",
    text: "Therefore, brothers, since we have confidence to enter the Most Holy Place by the blood of Jesus.",
    reference: "Hebrews 10:19",
    book: "Hebrews",
    chapter: 10,
    verse: 19
  },
  {
    date: "10-20",
    text: "Fear the LORD your God and serve him. Hold fast to him and take your oaths in his name.",
    reference: "Deuteronomy 10:20",
    book: "Deuteronomy",
    chapter: 10,
    verse: 20
  },
  {
    date: "10-21",
    text: "He is your praise; he is your God, who performed for you those great and awesome wonders you saw with your own eyes.",
    reference: "Deuteronomy 10:21",
    book: "Deuteronomy",
    chapter: 10,
    verse: 21
  },
  {
    date: "10-22",
    text: "Let us draw near to God with a sincere heart in full assurance of faith, having our hearts sprinkled to cleanse us from a guilty conscience and having our bodies washed with pure water.",
    reference: "Hebrews 10:22",
    book: "Hebrews",
    chapter: 10,
    verse: 22
  },
  {
    date: "10-23",
    text: "Let us hold unswervingly to the hope we profess, for he who promised is faithful.",
    reference: "Hebrews 10:23",
    book: "Hebrews",
    chapter: 10,
    verse: 23
  },
  {
    date: "10-24",
    text: "Nobody should seek his own good, but the good of others.",
    reference: "1 Corinthians 10:24",
    book: "1 Corinthians",
    chapter: 10,
    verse: 24
  },
  {
    date: "10-25",
    text: "Let us not give up meeting together, as some are in the habit of doing, but let us encourage one another and all the more as you see the Day approaching.",
    reference: "Hebrews 10:25",
    book: "Hebrews",
    chapter: 10,
    verse: 25
  },
  {
    date: "10-26",
    text: "For, The earth is the Lord's, and everything in it.",
    reference: "1 Corinthians 10:26",
    book: "1 Corinthians",
    chapter: 10,
    verse: 26
  },
  {
    date: "10-27",
    text: "Jesus looked at them and said, With man this is impossible, but not with God; all things are possible with God.",
    reference: "Mark 10:27",
    book: "Mark",
    chapter: 10,
    verse: 27
  },
  {
    date: "10-28",
    text: "I give them eternal life, and they shall never perish; no one can snatch them out of my hand.",
    reference: "John 10:28",
    book: "John",
    chapter: 10,
    verse: 28
  },
  {
    date: "10-29",
    text: "My Father, who has given them to me, is greater than all; no one can snatch them out of my Father's hand.",
    reference: "John 10:29",
    book: "John",
    chapter: 10,
    verse: 29
  },
  {
    date: "10-30",
    text: "I and the Father are one.",
    reference: "John 10:30",
    book: "John",
    chapter: 10,
    verse: 30
  },
  {
    date: "10-31",
    text: "So whether you eat or drink or whatever you do, do it all for the glory of God.",
    reference: "1 Corinthians 10:31",
    book: "1 Corinthians",
    chapter: 10,
    verse: 31
  }
];