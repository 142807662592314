import { BirthVerse } from '../../types/verses';

export const julyVerses: BirthVerse[] = [
  {
    date: "07-01",
    text: "Since we have these promises, dear friends, let us purify ourselves from everything that contaminates body and spirit, perfecting holiness out of reverence for God.",
    reference: "2 Corinthians 7:1",
    book: "2 Corinthians",
    chapter: 7,
    verse: 1
  },
  {
    date: "07-02",
    text: "Keep my commands and you will live; guard my teachings as the apple of your eye.",
    reference: "Proverbs 7:2",
    book: "Proverbs",
    chapter: 7,
    verse: 2
  },
  {
    date: "07-03",
    text: "And they worshiped and gave thanks to the LORD, saying, He is good; his love endures forever.",
    reference: "2 Chronicles 7:3",
    book: "2 Chronicles",
    chapter: 7,
    verse: 3
  },
  {
    date: "07-04",
    text: "So, my brothers, you also died to the law through the body of Christ, that you might belong to another, to him who was raised from the dead, in order that we might bear fruit to God.",
    reference: "Romans 7:4",
    book: "Romans",
    chapter: 7,
    verse: 4
  },
  {
    date: "07-05",
    text: "And Noah did all that the LORD commanded him.",
    reference: "Genesis 7:5",
    book: "Genesis",
    chapter: 7,
    verse: 5
  },
  {
    date: "07-06",
    text: "But now, by dying to what once bound us, we have been released from the law so that we serve in the new way of the Spirit, and not in the old way of the written code.",
    reference: "Romans 7:6",
    book: "Romans",
    chapter: 7,
    verse: 6
  },
  {
    date: "07-07",
    text: "Ask and it will be given to you; seek and you will find; knock and the door will be opened to you.",
    reference: "Matthew 7:7",
    book: "Matthew",
    chapter: 7,
    verse: 7
  },
  {
    date: "07-08",
    text: "For everyone who asks receives; he who seeks finds; and to him who knocks, the door will be opened.",
    reference: "Matthew 7:8",
    book: "Matthew",
    chapter: 7,
    verse: 8
  },
  {
    date: "07-09",
    text: "Know therefore that the LORD your God is God; he is the faithful God, keeping his covenant of love to a thousand generations of those who love him and keep his commands.",
    reference: "Deuteronomy 7:9",
    book: "Deuteronomy",
    chapter: 7,
    verse: 9
  },
  {
    date: "07-10",
    text: "My shield is God Most High, who saves the upright in heart.",
    reference: "Psalm 7:10",
    book: "Psalm",
    chapter: 7,
    verse: 10
  },
  {
    date: "07-11",
    text: "If you, then, though you are evil, know how to give good gifts to your children, how much more will your Father in heaven give good gifts to those who ask him!",
    reference: "Matthew 7:11",
    book: "Matthew",
    chapter: 7,
    verse: 11
  },
  {
    date: "07-12",
    text: "saying: Amen! Praise and glory and wisdom and thanks and honor and power and strength be to our God for ever and ever. Amen!",
    reference: "Revelation 7:12",
    book: "Revelation",
    chapter: 7,
    verse: 12
  },
  {
    date: "07-13",
    text: "He will love you and bless you and increase your numbers.",
    reference: "Deuteronomy 7:13",
    book: "Deuteronomy",
    chapter: 7,
    verse: 13
  },
  {
    date: "07-14",
    text: "if my people, who are called by my name, will humble themselves and pray and seek my face and turn from their wicked ways, then will I hear from heaven and will forgive their sin and will heal their land.",
    reference: "2 Chronicles 7:14",
    book: "2 Chronicles",
    chapter: 7,
    verse: 14
  },
  {
    date: "07-15",
    text: "Watch out for false prophets. They come to you in sheep's clothing, but inwardly they are ferocious wolves.",
    reference: "Matthew 7:15",
    book: "Matthew",
    chapter: 7,
    verse: 15
  },
  {
    date: "07-16",
    text: "The animals going in were male and female of every living thing, as God had commanded Noah. Then the LORD shut him in.",
    reference: "Genesis 7:16",
    book: "Genesis",
    chapter: 7,
    verse: 16
  },
  {
    date: "07-17",
    text: "Nevertheless, each one should retain the place in life that the Lord assigned to him and to which God has called him.",
    reference: "1 Corinthians 7:17",
    book: "1 Corinthians",
    chapter: 7,
    verse: 17
  },
  {
    date: "07-18",
    text: "He who speaks on his own does so to gain honor for himself, but he who works for the honor of the one who sent him is a man of truth; there is nothing false about him.",
    reference: "John 7:18",
    book: "John",
    chapter: 7,
    verse: 18
  },
  {
    date: "07-19",
    text: "Wisdom makes one wise man more powerful than ten rulers in a city.",
    reference: "Ecclesiastes 7:19",
    book: "Ecclesiastes",
    chapter: 7,
    verse: 19
  },
  {
    date: "07-20",
    text: "There is not a righteous man on earth who does what is right and never sins.",
    reference: "Ecclesiastes 7:20",
    book: "Ecclesiastes",
    chapter: 7,
    verse: 20
  },
  {
    date: "07-21",
    text: "Not everyone who says to me, Lord, Lord, will enter the kingdom of heaven, but only he who does the will of my Father who is in heaven.",
    reference: "Matthew 7:21",
    book: "Matthew",
    chapter: 7,
    verse: 21
  },
  {
    date: "07-22",
    text: "For in my inner being I delight in God's law.",
    reference: "Romans 7:22",
    book: "Romans",
    chapter: 7,
    verse: 22
  },
  {
    date: "07-23",
    text: "You were bought at a price; do not become slaves of men.",
    reference: "1 Corinthians 7:23",
    book: "1 Corinthians",
    chapter: 7,
    verse: 23
  },
  {
    date: "07-24",
    text: "Therefore everyone who hears these words of mine and puts them into practice is like a wise man who built his house on the rock.",
    reference: "Matthew 7:24",
    book: "Matthew",
    chapter: 7,
    verse: 24
  },
  {
    date: "07-25",
    text: "Therefore he is able to save completely those who come to God through him, because he always lives to intercede for them.",
    reference: "Hebrews 7:25",
    book: "Hebrews",
    chapter: 7,
    verse: 25
  },
  {
    date: "07-26",
    text: "But everyone who hears these words of mine and does not put them into practice is like a foolish man who built his house on sand.",
    reference: "Matthew 7:26",
    book: "Matthew",
    chapter: 7,
    verse: 26
  },
  {
    date: "07-27",
    text: "This is the one about whom it is written: I will send my messenger ahead of you, who will prepare your way before you.",
    reference: "Luke 7:27",
    book: "Luke",
    chapter: 7,
    verse: 27
  },
  {
    date: "07-28",
    text: "O Sovereign LORD, you are God! Your words are trustworthy, and you have promised these good things to your servant.",
    reference: "2 Samuel 7:28",
    book: "2 Samuel",
    chapter: 7,
    verse: 28
  },
  {
    date: "07-29",
    text: "Now be pleased to bless the house of your servant, that it may continue forever in your sight; for you, O Sovereign LORD, have spoken, and with your blessing the house of your servant will be blessed forever.",
    reference: "2 Samuel 7:29",
    book: "2 Samuel",
    chapter: 7,
    verse: 29
  },
  {
    date: "07-30",
    text: "At this they tried to seize him, but no one laid a hand on him, because his time had not yet come.",
    reference: "John 7:30",
    book: "John",
    chapter: 7,
    verse: 30
  },
  {
    date: "07-31",
    text: "Those who use the things of the world, as if not engrossed in them. For this world in its present form is passing away.",
    reference: "1 Corinthians 7:31",
    book: "1 Corinthians",
    chapter: 7,
    verse: 31
  }
];