import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col items-center space-y-4">
          <p className="text-center text-gray-500 text-sm">
            BirthVerse © {currentYear} • Spreading God's Word One Birthday at a Time
          </p>
          <div className="flex items-center space-x-4 text-sm text-gray-400">
            <Link 
              to="/privacy" 
              className="hover:text-gray-600 transition-colors"
            >
              Privacy Policy
            </Link>
            <span>•</span>
            <Link 
              to="/terms" 
              className="hover:text-gray-600 transition-colors"
            >
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;