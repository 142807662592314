import React, { useState } from 'react';
import { Heart, Twitter, Facebook, Linkedin, Share2, X, Instagram, MessageCircle } from 'lucide-react';

interface ShareButtonProps {
  title?: string;
  text?: string;
  url?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  title = "BirthVerse - Find Your Birthday Bible Verse",
  text = "Discover your personal Bible verse based on your birthday!",
  url = window.location.href
}) => {
  const [showPlatforms, setShowPlatforms] = useState(false);
  const [showInstructions, setShowInstructions] = useState<string | null>(null);

  const platforms = [
    {
      name: 'Twitter',
      icon: Twitter,
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`
    },
    {
      name: 'Facebook',
      icon: Facebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
    },
    {
      name: 'LinkedIn',
      icon: Linkedin,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`
    },
    {
      name: 'Reddit',
      icon: MessageCircle,
      url: `https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`
    },
    {
      name: 'Instagram',
      icon: Instagram,
      instructions: [
        '1. Copy the link (automatically copied)',
        '2. Open Instagram app',
        '3. Create a new Story or Post',
        '4. Paste the link in your Story or Bio',
        'Tip: Add a screenshot of your BirthVerse for more impact!'
      ]
    },
    {
      name: 'TikTok',
      icon: Share2,
      instructions: [
        '1. Copy the link (automatically copied)',
        '2. Open TikTok app',
        '3. Create a new video',
        '4. Add the link in your video description',
        'Tip: Record a video sharing your BirthVerse experience!'
      ]
    }
  ];

  const shareToSocialMedia = async (platform: typeof platforms[0]) => {
    if (platform.instructions) {
      await navigator.clipboard.writeText(url);
      setShowInstructions(platform.name);
      return;
    }

    if (platform.url) {
      const width = 550;
      const height = 400;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      window.open(
        platform.url,
        `Share on ${platform.name}`,
        `width=${width},height=${height},left=${left},top=${top},popup=yes`
      );
      setShowPlatforms(false);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share && window.isSecureContext) {
        await navigator.share({
          title,
          text,
          url
        });
      } else {
        setShowPlatforms(true);
      }
    } catch (error) {
      setShowPlatforms(true);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={handleShare}
        className="flex items-center space-x-2 px-4 py-2 text-[#4F46E5] hover:text-[#4338CA] transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#4F46E5] focus:ring-offset-2 rounded-lg"
        aria-label="Share God's Love"
      >
        <Heart className="h-5 w-5" />
        <span className="font-medium">Share God's Love</span>
      </button>

      {showPlatforms && (
        <div className="absolute top-full right-0 mt-2 bg-white rounded-lg shadow-xl border border-gray-100 p-2 min-w-[280px] z-50">
          <div className="flex justify-between items-center px-3 py-2 border-b border-gray-100">
            <span className="text-sm font-medium text-gray-700">Share via</span>
            <button
              onClick={() => {
                setShowPlatforms(false);
                setShowInstructions(null);
              }}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
          
          {showInstructions ? (
            <div className="p-4">
              <h3 className="font-medium text-gray-900 mb-3">Share on {showInstructions}</h3>
              <ul className="space-y-2 text-sm text-gray-600">
                {platforms.find(p => p.name === showInstructions)?.instructions?.map((instruction, i) => (
                  <li key={i} className="flex items-start">
                    <span className="block">{instruction}</span>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => setShowInstructions(null)}
                className="mt-4 w-full px-4 py-2 text-sm text-[#4F46E5] hover:bg-[#4F46E5] hover:text-white rounded-md transition-colors"
              >
                Back to sharing options
              </button>
            </div>
          ) : (
            <div className="p-1">
              {platforms.map((platform) => (
                <button
                  key={platform.name}
                  onClick={() => shareToSocialMedia(platform)}
                  className="flex items-center space-x-3 w-full px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md transition-colors"
                >
                  <platform.icon className="h-4 w-4" />
                  <span>Share on {platform.name}</span>
                </button>
              ))}
              {navigator.clipboard && (
                <>
                  <div className="border-t border-gray-100 my-1"></div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                      setShowPlatforms(false);
                    }}
                    className="flex items-center space-x-3 w-full px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md transition-colors"
                  >
                    <Share2 className="h-4 w-4" />
                    <span>Copy link</span>
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ShareButton;