import { BirthVerse } from '../../types/verses';

export const aprilVerses: BirthVerse[] = [
  {
    date: "04-01",
    text: "As a prisoner for the Lord, then, I urge you to live a life worthy of the calling you have received.",
    reference: "Ephesians 4:1",
    book: "Ephesians",
    chapter: 4,
    verse: 1
  },
  {
    date: "04-02",
    text: "Be completely humble and gentle; be patient, bearing with one another in love.",
    reference: "Ephesians 4:2",
    book: "Ephesians",
    chapter: 4,
    verse: 2
  },
  {
    date: "04-03",
    text: "Know that the LORD has set apart the godly for himself; the LORD will hear when I call to him.",
    reference: "Psalm 4:3",
    book: "Psalm",
    chapter: 4,
    verse: 3
  },
  {
    date: "04-04",
    text: "Jesus answered, It is written: Man does not live on bread alone, but on every word that comes from the mouth of God.",
    reference: "Matthew 4:4",
    book: "Matthew",
    chapter: 4,
    verse: 4
  },
  {
    date: "04-05",
    text: "All the nations may walk in the name of their gods; we will walk in the name of the LORD our God for ever and ever.",
    reference: "Micah 4:5",
    book: "Micah",
    chapter: 4,
    verse: 5
  },
  {
    date: "04-06",
    text: "Do not be anxious about anything, but in everything, by prayer and petition, with thanksgiving, present your requests to God.",
    reference: "Philippians 4:6",
    book: "Philippians",
    chapter: 4,
    verse: 6
  },
  {
    date: "04-07",
    text: "And the peace of God, which transcends all understanding, will guard your hearts and your minds in Christ Jesus.",
    reference: "Philippians 4:7",
    book: "Philippians",
    chapter: 4,
    verse: 7
  },
  {
    date: "04-08",
    text: "Finally, brothers, whatever is true, whatever is noble, whatever is right, whatever is pure, whatever is lovely, whatever is admirable if anything is excellent or praiseworthy think about such things.",
    reference: "Philippians 4:8",
    book: "Philippians",
    chapter: 4,
    verse: 8
  },
  {
    date: "04-09",
    text: "This is how God showed his love among us: He sent his one and only Son into the world that we might live through him.",
    reference: "1 John 4:9",
    book: "1 John",
    chapter: 4,
    verse: 9
  },
  {
    date: "04-10",
    text: "Jabez cried out to the God of Israel, Oh, that you would bless me and enlarge my territory! Let your hand be with me, and keep me from harm so that I will be free from pain. And God granted his request.",
    reference: "1 Chronicles 4:10",
    book: "1 Chronicles",
    chapter: 4,
    verse: 10
  },
  {
    date: "04-11",
    text: "Dear friends, since God so loved us, we also ought to love one another.",
    reference: "1 John 4:11",
    book: "1 John",
    chapter: 4,
    verse: 11
  },
  {
    date: "04-12",
    text: "No one has ever seen God; but if we love one another, God lives in us and his love is made complete in us.",
    reference: "1 John 4:12",
    book: "1 John",
    chapter: 4,
    verse: 12
  },
  {
    date: "04-13",
    text: "I can do everything through him who gives me strength.",
    reference: "Philippians 4:13",
    book: "Philippians",
    chapter: 4,
    verse: 13
  },
  {
    date: "04-14",
    text: "but whoever drinks the water I give him will never thirst. Indeed, the water I give him will become in him a spring of water welling up to eternal life.",
    reference: "John 4:14",
    book: "John",
    chapter: 4,
    verse: 14
  },
  {
    date: "04-15",
    text: "If anyone acknowledges that Jesus is the Son of God, God lives in him and he in God.",
    reference: "1 John 4:15",
    book: "1 John",
    chapter: 4,
    verse: 15
  },
  {
    date: "04-16",
    text: "Let us then approach the throne of grace with confidence, so that we may receive mercy and find grace to help us in our time of need.",
    reference: "Hebrews 4:16",
    book: "Hebrews",
    chapter: 4,
    verse: 16
  },
  {
    date: "04-17",
    text: "In this way, love is made complete among us so that we will have confidence on the day of judgment, because in this world we are like him.",
    reference: "1 John 4:17",
    book: "1 John",
    chapter: 4,
    verse: 17
  },
  {
    date: "04-18",
    text: "So we fix our eyes not on what is seen, but on what is unseen. For what is seen is temporary, but what is unseen is eternal.",
    reference: "2 Corinthians 4:18",
    book: "2 Corinthians",
    chapter: 4,
    verse: 18
  },
  {
    date: "04-19",
    text: "And my God will meet all your needs according to his glorious riches in Christ Jesus.",
    reference: "Philippians 4:19",
    book: "Philippians",
    chapter: 4,
    verse: 19
  },
  {
    date: "04-20",
    text: "If anyone says, I love God, yet hates his brother, he is a liar. For anyone who does not love his brother, whom he has seen, cannot love God, whom he has not seen.",
    reference: "1 John 4:20",
    book: "1 John",
    chapter: 4,
    verse: 20
  },
  {
    date: "04-21",
    text: "And he has given us this command: Whoever loves God must also love his brother.",
    reference: "1 John 4:21",
    book: "1 John",
    chapter: 4,
    verse: 21
  },
  {
    date: "04-22",
    text: "For they are life to those who find them and health to a man's whole body.",
    reference: "Proverbs 4:22",
    book: "Proverbs",
    chapter: 4,
    verse: 22
  },
  {
    date: "04-23",
    text: "Above all else, guard your heart, for it is the wellspring of life.",
    reference: "Proverbs 4:23",
    book: "Proverbs",
    chapter: 4,
    verse: 23
  },
  {
    date: "04-24",
    text: "but also for us, to whom God will credit righteousness for us who believe in him who raised Jesus our Lord from the dead.",
    reference: "Romans 4:24",
    book: "Romans",
    chapter: 4,
    verse: 24
  },
  {
    date: "04-25",
    text: "Let your eyes look straight ahead, fix your gaze directly before you.",
    reference: "Proverbs 4:25",
    book: "Proverbs",
    chapter: 4,
    verse: 25
  },
  {
    date: "04-26",
    text: "In your anger do not sin: Do not let the sun go down while you are still angry.",
    reference: "Ephesians 4:26",
    book: "Ephesians",
    chapter: 4,
    verse: 26
  },
  {
    date: "04-27",
    text: "Do not swerve to the right or the left; keep your foot from evil.",
    reference: "Proverbs 4:27",
    book: "Proverbs",
    chapter: 4,
    verse: 27
  },
  {
    date: "04-28",
    text: "He who has been stealing must steal no longer, but must work, doing something useful with his own hands, that he may have something to share with those in need.",
    reference: "Ephesians 4:28",
    book: "Ephesians",
    chapter: 4,
    verse: 28
  },
  {
    date: "04-29",
    text: "But if from there you seek the LORD your God, you will find him if you look for him with all your heart and with all your soul.",
    reference: "Deuteronomy 4:29",
    book: "Deuteronomy",
    chapter: 4,
    verse: 29
  },
  {
    date: "04-30",
    text: "And do not grieve the Holy Spirit of God, with whom you were sealed for the day of redemption.",
    reference: "Ephesians 4:30",
    book: "Ephesians",
    chapter: 4,
    verse: 30
  }
];