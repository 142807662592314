import { BirthVerse } from '../../types/verses';

export const augustVerses: BirthVerse[] = [
  {
    date: "08-01",
    text: "Therefore, there is now no condemnation for those who are in Christ Jesus.",
    reference: "Romans 8:1",
    book: "Romans",
    chapter: 8,
    verse: 1
  },
  {
    date: "08-02",
    text: "Because through Christ Jesus the law of the Spirit of life set me free from the law of sin and death.",
    reference: "Romans 8:2",
    book: "Romans",
    chapter: 8,
    verse: 2
  },
  {
    date: "08-03",
    text: "But the man who loves God is known by God.",
    reference: "1 Corinthians 8:3",
    book: "1 Corinthians",
    chapter: 8,
    verse: 3
  },
  {
    date: "08-04",
    text: "The righteous requirements of the law might be fully met in us, who do not live according to the sinful nature but according to the Spirit.",
    reference: "Romans 8:4",
    book: "Romans",
    chapter: 8,
    verse: 4
  },
  {
    date: "08-05",
    text: "Those who live according to the sinful nature have their minds set on what that nature desires; but those who live in accordance with the Spirit have their minds set on what the Spirit desires.",
    reference: "Romans 8:5",
    book: "Romans",
    chapter: 8,
    verse: 5
  },
  {
    date: "08-06",
    text: "Yet for us there is but one God, the Father, from whom all things came and for whom we live; and there is but one Lord, Jesus Christ, through whom all things came and through whom we live.",
    reference: "1 Corinthians 8:6",
    book: "1 Corinthians",
    chapter: 8,
    verse: 6
  },
  {
    date: "08-07",
    text: "For the LORD your God is bringing you into a good land a land with streams and pools of water, with springs flowing in the valleys and hills.",
    reference: "Deuteronomy 8:7",
    book: "Deuteronomy",
    chapter: 8,
    verse: 7
  },
  {
    date: "08-08",
    text: "They will be my people, and I will be faithful and righteous to them as their God.",
    reference: "Zechariah 8:8",
    book: "Zechariah",
    chapter: 8,
    verse: 8
  },
  {
    date: "08-09",
    text: "For you know the grace of our Lord Jesus Christ, that though he was rich, yet for your sakes he became poor, so that you through his poverty might become rich.",
    reference: "2 Corinthians 8:9",
    book: "2 Corinthians",
    chapter: 8,
    verse: 9
  },
  {
    date: "08-10",
    text: "Nehemiah said, Go and enjoy choice food and sweet drinks, and send some to those who have nothing prepared. This day is sacred to our Lord. Do not grieve, for the joy of the LORD is your strength.",
    reference: "Nehemiah 8:10",
    book: "Nehemiah",
    chapter: 8,
    verse: 10
  },
  {
    date: "08-11",
    text: "And if the Spirit of him who raised Jesus from the dead is living in you, he who raised Christ from the dead will also give life to your mortal bodies through his Spirit, who lives in you.",
    reference: "Romans 8:11",
    book: "Romans",
    chapter: 8,
    verse: 11
  },
  {
    date: "08-12",
    text: "When Jesus spoke again to the people, he said, I am the light of the world. Whoever follows me will never walk in darkness, but will have the light of life.",
    reference: "John 8:12",
    book: "John",
    chapter: 8,
    verse: 12
  },
  {
    date: "08-13",
    text: "The LORD Almighty is the one you are to regard as holy, he is the one you are to fear.",
    reference: "Isaiah 8:13",
    book: "Isaiah",
    chapter: 8,
    verse: 13
  },
  {
    date: "08-14",
    text: "Because those who are led by the Spirit of God are sons of God.",
    reference: "Romans 8:14",
    book: "Romans",
    chapter: 8,
    verse: 14
  },
  {
    date: "08-15",
    text: "For you did not receive a spirit that makes you a slave again to fear, but you received the Spirit of sonship. And by him we cry, Abba, Father.",
    reference: "Romans 8:15",
    book: "Romans",
    chapter: 8,
    verse: 15
  },
  {
    date: "08-16",
    text: "The Spirit himself testifies with our spirit that we are God's children.",
    reference: "Romans 8:16",
    book: "Romans",
    chapter: 8,
    verse: 16
  },
  {
    date: "08-17",
    text: "Now if we are children, then we are heirs, heirs of God and co-heirs with Christ, if indeed we share in his sufferings in order that we may also share in his glory.",
    reference: "Romans 8:17",
    book: "Romans",
    chapter: 8,
    verse: 17
  },
  {
    date: "08-18",
    text: "I consider that our present sufferings are not worth comparing with the glory that will be revealed in us.",
    reference: "Romans 8:18",
    book: "Romans",
    chapter: 8,
    verse: 18
  },
  {
    date: "08-19",
    text: "The creation waits in eager expectation for the sons of God to be revealed.",
    reference: "Romans 8:19",
    book: "Romans",
    chapter: 8,
    verse: 19
  },
  {
    date: "08-20",
    text: "I walk in the way of righteousness, along the paths of justice.",
    reference: "Proverbs 8:20",
    book: "Proverbs",
    chapter: 8,
    verse: 20
  },
  {
    date: "08-21",
    text: "Bestowing wealth on those who love me and making their treasuries full.",
    reference: "Proverbs 8:21",
    book: "Proverbs",
    chapter: 8,
    verse: 21
  },
  {
    date: "08-22",
    text: "The LORD brought me forth as the first of his works, before his deeds of old.",
    reference: "Proverbs 8:22",
    book: "Proverbs",
    chapter: 8,
    verse: 22
  },
  {
    date: "08-23",
    text: "And said: O LORD, God of Israel, there is no God like you in heaven above or on earth below you who keep your covenant of love with your servants who continue wholeheartedly in your way.",
    reference: "1 Kings 8:23",
    book: "1 Kings",
    chapter: 8,
    verse: 23
  },
  {
    date: "08-24",
    text: "For in this hope we were saved. But hope that is seen is no hope at all. Who hopes for what he already has?",
    reference: "Romans 8:24",
    book: "Romans",
    chapter: 8,
    verse: 24
  },
  {
    date: "08-25",
    text: "Once more Jesus put his hands on the man's eyes. Then his eyes were opened, his sight was restored, and he saw everything clearly.",
    reference: "Mark 8:25",
    book: "Mark",
    chapter: 8,
    verse: 25
  },
  {
    date: "08-26",
    text: "In the same way, the Spirit helps us in our weakness. We do not know what we ought to pray for, but the Spirit himself intercedes for us with groans that words cannot express.",
    reference: "Romans 8:26",
    book: "Romans",
    chapter: 8,
    verse: 26
  },
  {
    date: "08-27",
    text: "And he who searches our hearts knows the mind of the Spirit, because the Spirit intercedes for the saints in accordance with God's will.",
    reference: "Romans 8:27",
    book: "Romans",
    chapter: 8,
    verse: 27
  },
  {
    date: "08-28",
    text: "And we know that in all things God works for the good of those who love him, who have been called according to his purpose.",
    reference: "Romans 8:28",
    book: "Romans",
    chapter: 8,
    verse: 28
  },
  {
    date: "08-29",
    text: "For those God foreknew he also predestined to be conformed to the likeness of his Son.",
    reference: "Romans 8:29",
    book: "Romans",
    chapter: 8,
    verse: 29
  },
  {
    date: "08-30",
    text: "And those he predestined, he also called; those he called, he also justified; those he justified, he also glorified.",
    reference: "Romans 8:30",
    book: "Romans",
    chapter: 8,
    verse: 30
  },
  {
    date: "08-31",
    text: "What, then, shall we say in response to this? If God is for us, who can be against us?",
    reference: "Romans 8:31",
    book: "Romans",
    chapter: 8,
    verse: 31
  }
];