import React, { useState } from 'react';
import { Calendar, ChevronDown, Sparkles } from 'lucide-react';

interface DatePickerProps {
  onDateSelect: (date: Date) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ onDateSelect }) => {
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isDayOpen, setIsDayOpen] = useState(false);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (month && day) {
      const date = new Date(2024, parseInt(month) - 1, parseInt(day));
      onDateSelect(date);
    }
  };

  const getDaysInMonth = (month: string) => {
    if (!month) return 31;
    const daysInMonth = new Date(2024, parseInt(month), 0).getDate();
    return daysInMonth;
  };

  const handleMonthSelect = (index: number) => {
    setMonth((index + 1).toString());
    setIsMonthOpen(false);
    setDay(''); // Reset day when month changes
    setIsDayOpen(true); // Open day selector after month selection
  };

  const handleDaySelect = (day: number) => {
    setDay(day.toString());
    setIsDayOpen(false);
  };

  return (
    <form onSubmit={handleSubmit} className="relative">
      <div className="flex items-center justify-center space-x-3 mb-6 sm:mb-8">
        <div className="bg-[#F5F3FF] p-2 sm:p-2.5 rounded-xl">
          <Calendar className="h-5 w-5 sm:h-6 sm:w-6 text-[#4F46E5]" />
        </div>
        <h2 className="text-xl sm:text-2xl font-bold text-gray-900">Select Your Birthday</h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mb-6 sm:mb-8">
        {/* Month Selector */}
        <div className="relative">
          <label className="block text-sm sm:text-base font-semibold text-gray-700 mb-2">
            Month
          </label>
          <button
            type="button"
            className={`w-full px-3 sm:px-4 py-3 sm:py-3.5 text-left bg-white border-2 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-[#4F46E5] transition-all duration-200 ${isMonthOpen ? 'border-[#4F46E5]' : 'border-gray-200 hover:border-[#4F46E5]'
              }`}
            onClick={() => {
              setIsMonthOpen(!isMonthOpen);
              setIsDayOpen(false);
            }}
          >
            <div className="flex items-center justify-between">
              <span className={`text-sm sm:text-base ${month ? 'text-gray-900 font-medium' : 'text-gray-500'}`}>
                {month ? months[parseInt(month) - 1] : 'Select month'}
              </span>
              <ChevronDown className={`h-4 w-4 sm:h-5 sm:w-5 text-gray-400 transition-transform ${isMonthOpen ? 'transform rotate-180' : ''
                }`} />
            </div>
          </button>

          {isMonthOpen && (
            <div className="absolute z-20 w-full mt-2 bg-white border-2 border-[#4F46E5] rounded-xl shadow-lg max-h-48 sm:max-h-60 overflow-auto">
              <div className="py-1">
                {months.map((monthName, index) => (
                  <button
                    key={monthName}
                    type="button"
                    className={`w-full px-3 sm:px-4 py-2 sm:py-2.5 text-left text-sm sm:text-base hover:bg-[#F5F3FF] transition-colors ${month === (index + 1).toString() ? 'bg-[#F5F3FF] text-[#4F46E5] font-semibold' : 'text-gray-700'
                      }`}
                    onClick={() => handleMonthSelect(index)}
                  >
                    {monthName}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Day Selector */}
        <div className="relative">
          <label className="block text-sm sm:text-base font-semibold text-gray-700 mb-2">
            Day
          </label>
          <button
            type="button"
            className={`w-full px-3 sm:px-4 py-3 sm:py-3.5 text-left bg-white border-2 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-[#4F46E5] transition-all duration-200 ${isDayOpen ? 'border-[#4F46E5]' : 'border-gray-200 hover:border-[#4F46E5]'
              } ${!month ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => {
              if (month) {
                setIsDayOpen(!isDayOpen);
                setIsMonthOpen(false);
              }
            }}
            disabled={!month}
          >
            <div className="flex items-center justify-between">
              <span className={`text-sm sm:text-base ${day ? 'text-gray-900 font-medium' : 'text-gray-500'}`}>
                {day ? `Day ${day}` : 'Select day'}
              </span>
              <ChevronDown className={`h-4 w-4 sm:h-5 sm:w-5 text-gray-400 transition-transform ${isDayOpen ? 'transform rotate-180' : ''
                }`} />
            </div>
          </button>

          {isDayOpen && (
            <div className="absolute z-20 w-full mt-2 bg-white border-2 border-[#4F46E5] rounded-xl shadow-lg max-h-48 sm:max-h-60 overflow-auto">
              <div className="py-2 grid grid-cols-5 sm:grid-cols-7 gap-1 p-2">
                {[...Array(getDaysInMonth(month))].map((_, i) => (
                  <button
                    key={i + 1}
                    type="button"
                    className={`aspect-square rounded-lg flex items-center justify-center text-sm sm:text-base hover:bg-[#F5F3FF] transition-colors ${day === (i + 1).toString()
                        ? 'bg-[#F5F3FF] text-[#4F46E5] font-semibold'
                        : 'text-gray-700'
                      }`}
                    onClick={() => handleDaySelect(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <button
        type="submit"
        disabled={!month || !day}
        className={`relative w-full py-3 sm:py-4 rounded-xl font-bold text-base sm:text-lg transition-all duration-300 ${month && day
            ? 'bg-gradient-to-r from-[#4F46E5] to-[#7C3AED] text-white hover:scale-[1.02] hover:shadow-lg'
            : 'bg-gray-100 text-gray-400 cursor-not-allowed'
          }`}
      >
        <div className="flex items-center justify-center space-x-2">
          <span>Find My BirthVerse</span>
          {month && day && <Sparkles className="h-4 w-4 sm:h-5 sm:w-5" />}
        </div>
        {month && day && (
          <div className="absolute inset-0 bg-white rounded-xl opacity-0 hover:opacity-10 transition-opacity" />
        )}
      </button>
    </form>
  );
};

export default DatePicker;