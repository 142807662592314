import { BirthVerse } from '../../types/verses';

export const novemberVerses: BirthVerse[] = [
  {
    date: "11-01",
    text: "Now faith is being sure of what we hope for and certain of what we do not see.",
    reference: "Hebrews 11:1",
    book: "Hebrews",
    chapter: 11,
    verse: 1
  },
  {
    date: "11-02",
    text: "When pride comes, then comes disgrace, but with humility comes wisdom.",
    reference: "Proverbs 11:2",
    book: "Proverbs",
    chapter: 11,
    verse: 2
  },
  {
    date: "11-03",
    text: "Give us each day our daily bread.",
    reference: "Luke 11:3",
    book: "Luke",
    chapter: 11,
    verse: 3
  },
  {
    date: "11-04",
    text: "The LORD is in his holy temple; the LORD is on his heavenly throne. He observes the sons of men; his eyes examine them.",
    reference: "Psalm 11:4",
    book: "Psalm",
    chapter: 11,
    verse: 4
  },
  {
    date: "11-05",
    text: "As you do not know the path of the wind, or how the body is formed in a mother's womb, so you cannot understand the work of God, the Maker of all things.",
    reference: "Ecclesiastes 11:5",
    book: "Ecclesiastes",
    chapter: 11,
    verse: 5
  },
  {
    date: "11-06",
    text: "And without faith it is impossible to please God, because anyone who comes to him must believe that he exists and that he rewards those who earnestly seek him.",
    reference: "Hebrews 11:6",
    book: "Hebrews",
    chapter: 11,
    verse: 6
  },
  {
    date: "11-07",
    text: "For the LORD is righteous, he loves justice; upright men will see his face.",
    reference: "Psalm 11:7",
    book: "Psalm",
    chapter: 11,
    verse: 7
  },
  {
    date: "11-08",
    text: "The righteous man is rescued from trouble, and it comes on the wicked instead.",
    reference: "Proverbs 11:8",
    book: "Proverbs",
    chapter: 11,
    verse: 8
  },
  {
    date: "11-09",
    text: "So I say to you: Ask and it will be given to you; seek and you will find; knock and the door will be opened to you.",
    reference: "Luke 11:9",
    book: "Luke",
    chapter: 11,
    verse: 9
  },
  {
    date: "11-10",
    text: "For everyone who asks receives; he who seeks finds; and to him who knocks, the door will be opened.",
    reference: "Luke 11:10",
    book: "Luke",
    chapter: 11,
    verse: 10
  },
  {
    date: "11-11",
    text: "After he had said this, he went on to tell them, Our friend Lazarus has fallen asleep; but I am going there to wake him up.",
    reference: "John 11:11",
    book: "John",
    chapter: 11,
    verse: 11
  },
  {
    date: "11-12",
    text: "For as woman came from man, so also man is born of woman. But everything comes from God.",
    reference: "1 Corinthians 11:12",
    book: "1 Corinthians",
    chapter: 11,
    verse: 12
  },
  {
    date: "11-13",
    text: "If you then, though you are evil, know how to give good gifts to your children, how much more will your Father in heaven give the Holy Spirit to those who ask him!",
    reference: "Luke 11:13",
    book: "Luke",
    chapter: 11,
    verse: 13
  },
  {
    date: "11-14",
    text: "He will bring you a message through which you and all your household will be saved.",
    reference: "Acts 11:14",
    book: "Acts",
    chapter: 11,
    verse: 14
  },
  {
    date: "11-15",
    text: "The seventh angel sounded his trumpet, and there were loud voices in heaven, which said: The kingdom of the world has become the kingdom of our Lord and of his Christ, and he will reign for ever and ever.",
    reference: "Revelation 11:15",
    book: "Revelation",
    chapter: 11,
    verse: 15
  },
  {
    date: "11-16",
    text: "Then I remembered what the Lord had said: John baptized with water, but you will be baptized with the Holy Spirit.",
    reference: "Acts 11:16",
    book: "Acts",
    chapter: 11,
    verse: 16
  },
  {
    date: "11-17",
    text: "We give thanks to you, Lord God Almighty, the One who is and who was, because you have taken your great power and have begun to reign.",
    reference: "Revelation 11:17",
    book: "Revelation",
    chapter: 11,
    verse: 17
  },
  {
    date: "11-18",
    text: "Fix these words of mine in your hearts and minds; tie them as symbols on your hands and bind them on your foreheads.",
    reference: "Deuteronomy 11:18",
    book: "Deuteronomy",
    chapter: 11,
    verse: 18
  },
  {
    date: "11-19",
    text: "Teach them to your children, talking about them when you sit at home and when you walk along the road, when you lie down and when you get up.",
    reference: "Deuteronomy 11:19",
    book: "Deuteronomy",
    chapter: 11,
    verse: 19
  },
  {
    date: "11-20",
    text: "Then they will follow my decrees and be careful to keep my laws. They will be my people, and I will be their God.",
    reference: "Ezekiel 11:20",
    book: "Ezekiel",
    chapter: 11,
    verse: 20
  },
  {
    date: "11-21",
    text: "The Lord's hand was with them, and a great number of people believed and turned to the Lord.",
    reference: "Acts 11:21",
    book: "Acts",
    chapter: 11,
    verse: 21
  },
  {
    date: "11-22",
    text: "Have faith in God, Jesus answered.",
    reference: "Mark 11:22",
    book: "Mark",
    chapter: 11,
    verse: 22
  },
  {
    date: "11-23",
    text: "I tell you the truth, if anyone says to this mountain, Go, throw yourself into the sea, and does not doubt in his heart but believes that what he says will happen, it will be done for him.",
    reference: "Mark 11:23",
    book: "Mark",
    chapter: 11,
    verse: 23
  },
  {
    date: "11-24",
    text: "and when he had given thanks, he broke it and said, This is my body, which is for you; do this in remembrance of me.",
    reference: "1 Corinthians 11:24",
    book: "1 Corinthians",
    chapter: 11,
    verse: 24
  },
  {
    date: "11-25",
    text: "Jesus said to her, I am the resurrection and the life. He who believes in me will live, even though he dies.",
    reference: "John 11:25",
    book: "John",
    chapter: 11,
    verse: 25
  },
  {
    date: "11-26",
    text: "and whoever lives and believes in me will never die. Do you believe this?",
    reference: "John 11:26",
    book: "John",
    chapter: 11,
    verse: 26
  },
  {
    date: "11-27",
    text: "Yes, Lord, she told him, I believe that you are the Christ, the Son of God, who was to come into the world.",
    reference: "John 11:27",
    book: "John",
    chapter: 11,
    verse: 27
  },
  {
    date: "11-28",
    text: "Come to me, all you who are weary and burdened, and I will give you rest.",
    reference: "Matthew 11:28",
    book: "Matthew",
    chapter: 11,
    verse: 28
  },
  {
    date: "11-29",
    text: "Take my yoke upon you and learn from me, for I am gentle and humble in heart, and you will find rest for your souls.",
    reference: "Matthew 11:29",
    book: "Matthew",
    chapter: 11,
    verse: 29
  },
  {
    date: "11-30",
    text: "The fruit of the righteous is a tree of life, and he who wins souls is wise.",
    reference: "Proverbs 11:30",
    book: "Proverbs",
    chapter: 11,
    verse: 30
  }
];