import { BirthVerse } from '../../types/verses';

export const marchVerses: BirthVerse[] = [
  {
    date: "03-01",
    text: "How great is the love the Father has lavished on us, that we should be called children of God! And that is what we are!",
    reference: "1 John 3:1",
    book: "1 John",
    chapter: 3,
    verse: 1
  },
  {
    date: "03-02",
    text: "Dear friends, now we are children of God, and what we will be has not yet been made known. But we know that when he appears, we shall be like him, for we shall see him as he is.",
    reference: "1 John 3:2",
    book: "1 John",
    chapter: 3,
    verse: 2
  },
  {
    date: "03-03",
    text: "But you are a shield around me, O LORD; you bestow glory on me and lift up my head.",
    reference: "Psalm 3:3",
    book: "Psalm",
    chapter: 3,
    verse: 3
  },
  {
    date: "03-04",
    text: "When Christ, who is your life, appears, then you also will appear with him in glory.",
    reference: "Colossians 3:4",
    book: "Colossians",
    chapter: 3,
    verse: 4
  },
  {
    date: "03-05",
    text: "he saved us, not because of righteous things we had done, but because of his mercy. He saved us through the washing of rebirth and renewal by the Holy Spirit.",
    reference: "Titus 3:5",
    book: "Titus",
    chapter: 3,
    verse: 5
  },
  {
    date: "03-06",
    text: "in all your ways acknowledge him, and he will make your paths straight.",
    reference: "Proverbs 3:6",
    book: "Proverbs",
    chapter: 3,
    verse: 6
  },
  {
    date: "03-07",
    text: "so that, having been justified by his grace, we might become heirs having the hope of eternal life.",
    reference: "Titus 3:7",
    book: "Titus",
    chapter: 3,
    verse: 7
  },
  {
    date: "03-08",
    text: "What is more, I consider everything a loss compared to the surpassing greatness of knowing Christ Jesus my Lord, for whose sake I have lost all things. I consider them rubbish, that I may gain Christ.",
    reference: "Philippians 3:8",
    book: "Philippians",
    chapter: 3,
    verse: 8
  },
  {
    date: "03-09",
    text: "Do not repay evil with evil or insult with insult, but with blessing, because to this you were called so that you may inherit a blessing.",
    reference: "1 Peter 3:9",
    book: "1 Peter",
    chapter: 3,
    verse: 9
  },
  {
    date: "03-10",
    text: "Put on the new self, which is being renewed in knowledge in the image of its Creator.",
    reference: "Colossians 3:10",
    book: "Colossians",
    chapter: 3,
    verse: 10
  },
  {
    date: "03-11",
    text: "He has made everything beautiful in its time. He has also set eternity in the hearts of men; yet they cannot fathom what God has done from the beginning to end.",
    reference: "Ecclesiastes 3:11",
    book: "Ecclesiastes",
    chapter: 3,
    verse: 11
  },
  {
    date: "03-12",
    text: "In him and through faith in him we may approach God with freedom and confidence.",
    reference: "Ephesians 3:12",
    book: "Ephesians",
    chapter: 3,
    verse: 12
  },
  {
    date: "03-13",
    text: "May he strengthen your hearts so that you will be blameless and holy in the presence of our God and Father when our Lord Jesus comes with all his holy ones.",
    reference: "1 Thessalonians 3:13",
    book: "1 Thessalonians",
    chapter: 3,
    verse: 13
  },
  {
    date: "03-14",
    text: "I press on toward the goal to win the prize for which God has called me heavenward in Christ Jesus.",
    reference: "Philippians 3:14",
    book: "Philippians",
    chapter: 3,
    verse: 14
  },
  {
    date: "03-15",
    text: "Let the peace of Christ rule in your hearts, since as members of one body you were called to peace. And be thankful.",
    reference: "Colossians 3:15",
    book: "Colossians",
    chapter: 3,
    verse: 15
  },
  {
    date: "03-16",
    text: "For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life.",
    reference: "John 3:16",
    book: "John",
    chapter: 3,
    verse: 16
  },
  {
    date: "03-17",
    text: "The LORD your God is with you, he is mighty to save. He will take great delight in you, he will quiet you with his love, he will rejoice over you with singing.",
    reference: "Zephaniah 3:17",
    book: "Zephaniah",
    chapter: 3,
    verse: 17
  },
  {
    date: "03-18",
    text: "And we, who with unveiled faces all reflect the Lord's glory, are being transformed into his likeness with ever increasing glory, which comes from the Lord, who is the Spirit.",
    reference: "2 Corinthians 3:18",
    book: "2 Corinthians",
    chapter: 3,
    verse: 18
  },
  {
    date: "03-19",
    text: "The Sovereign LORD is my strength; he makes my feet like the feet of a deer, he enables me to go on the heights.",
    reference: "Habakkuk 3:19",
    book: "Habakkuk",
    chapter: 3,
    verse: 19
  },
  {
    date: "03-20",
    text: "Here I am! I stand at the door and knock. If anyone hears my voice and opens the door, I will come in and eat with him, and he with me.",
    reference: "Revelation 3:20",
    book: "Revelation",
    chapter: 3,
    verse: 20
  },
  {
    date: "03-21",
    text: "But whoever lives by the truth comes into the light, so that it may be seen plainly that what he has done has been done through God.",
    reference: "John 3:21",
    book: "John",
    chapter: 3,
    verse: 21
  },
  {
    date: "03-22",
    text: "Because of the LORD's great love we are not consumed, for his compassions never fail.",
    reference: "Lamentations 3:22",
    book: "Lamentations",
    chapter: 3,
    verse: 22
  },
  {
    date: "03-23",
    text: "Whatever you do, work at it with all your heart, as working for the Lord, not for men.",
    reference: "Colossians 3:23",
    book: "Colossians",
    chapter: 3,
    verse: 23
  },
  {
    date: "03-24",
    text: "Those who obey his commands live in him, and he in them. And this is how we know that he lives in us: We know it by the Spirit he gave us.",
    reference: "1 John 3:24",
    book: "1 John",
    chapter: 3,
    verse: 24
  },
  {
    date: "03-25",
    text: "The LORD is good to those whose hope is in him, to the one who seeks him.",
    reference: "Lamentations 3:25",
    book: "Lamentations",
    chapter: 3,
    verse: 25
  },
  {
    date: "03-26",
    text: "for the LORD will be your confidence and will keep your foot from being snared.",
    reference: "Proverbs 3:26",
    book: "Proverbs",
    chapter: 3,
    verse: 26
  },
  {
    date: "03-27",
    text: "for all of you who were baptized into Christ have clothed yourselves with Christ.",
    reference: "Galatians 3:27",
    book: "Galatians",
    chapter: 3,
    verse: 27
  },
  {
    date: "03-28",
    text: "There is neither Jew nor Greek, slave nor free, male nor female, for you are all one in Christ Jesus.",
    reference: "Galatians 3:28",
    book: "Galatians",
    chapter: 3,
    verse: 28
  },
  {
    date: "03-29",
    text: "If you belong to Christ, then you are Abraham's seed, and heirs according to the promise.",
    reference: "Galatians 3:29",
    book: "Galatians",
    chapter: 3,
    verse: 29
  },
  {
    date: "03-30",
    text: "He must become greater; I must become less.",
    reference: "John 3:30",
    book: "John",
    chapter: 3,
    verse: 30
  },
  {
    date: "03-31",
    text: "The one who comes from above is above all; the one who is from the earth belongs to the earth, and speaks as one from the earth. The one who comes from heaven is above all.",
    reference: "John 3:31",
    book: "John",
    chapter: 3,
    verse: 31
  }
];