import { BirthVerse } from '../../types/verses';

export const februaryVerses: BirthVerse[] = [
  {
    date: "02-01",
    text: "Be strong in the grace that is in Christ Jesus.",
    reference: "2 Timothy 2:1",
    book: "2 Timothy",
    chapter: 2,
    verse: 1
  },
  {
    date: "02-02",
    text: "Like newborn babies, crave pure spiritual milk, so that by it you may grow up in your salvation.",
    reference: "1 Peter 2:2",
    book: "1 Peter",
    chapter: 2,
    verse: 2
  },
  {
    date: "02-03",
    text: "Do nothing out of selfish ambition or vain conceit, but in humility consider others better than yourselves.",
    reference: "Philippians 2:3",
    book: "Philippians",
    chapter: 2,
    verse: 3
  },
  {
    date: "02-04",
    text: "God also testified to it by signs, wonders and various miracles, and gifts of the Holy Spirit distributed according to his will.",
    reference: "Hebrews 2:4",
    book: "Hebrews",
    chapter: 2,
    verse: 4
  },
  {
    date: "02-05",
    text: "Alive with Christ even when we were dead in transgressions it is by grace you have been saved.",
    reference: "Ephesians 2:5",
    book: "Ephesians",
    chapter: 2,
    verse: 5
  },
  {
    date: "02-06",
    text: "And God raised us up with Christ and seated us with him in the heavenly realms in Christ Jesus.",
    reference: "Ephesians 2:6",
    book: "Ephesians",
    chapter: 2,
    verse: 6
  },
  {
    date: "02-07",
    text: "He holds victory in store for the upright, he is a shield to those whose walk is blameless.",
    reference: "Proverbs 2:7",
    book: "Proverbs",
    chapter: 2,
    verse: 7
  },
  {
    date: "02-08",
    text: "For it is by grace you have been saved, through faith and this not from yourselves, it is the gift of God.",
    reference: "Ephesians 2:8",
    book: "Ephesians",
    chapter: 2,
    verse: 8
  },
  {
    date: "02-09",
    text: "However, as it is written: No eye has seen, no ear has heard, no mind has conceived what God has prepared for those who love him.",
    reference: "1 Corinthians 2:9",
    book: "1 Corinthians",
    chapter: 2,
    verse: 9
  },
  {
    date: "02-10",
    text: "For we are God's workmanship, created in Christ Jesus to do good works, which God prepared in advance for us to do.",
    reference: "Ephesians 2:10",
    book: "Ephesians",
    chapter: 2,
    verse: 10
  },
  {
    date: "02-11",
    text: "Serve the LORD with fear and rejoice with trembling.",
    reference: "Psalm 2:11",
    book: "Psalm",
    chapter: 2,
    verse: 11
  },
  {
    date: "02-12",
    text: "We have not received the spirit of the world but the Spirit who is from God, that we may understand what God has freely given us.",
    reference: "1 Corinthians 2:12",
    book: "1 Corinthians",
    chapter: 2,
    verse: 12
  },
  {
    date: "02-13",
    text: "for it is God who works in you to will and to act according to his good purpose.",
    reference: "Philippians 2:13",
    book: "Philippians",
    chapter: 2,
    verse: 13
  },
  {
    date: "02-14",
    text: "who gave himself for us to redeem us from all wickedness and to purify for himself a people that are his very own, eager to do what is good.",
    reference: "Titus 2:14",
    book: "Titus",
    chapter: 2,
    verse: 14
  },
  {
    date: "02-15",
    text: "Do your best to present yourself to God as one approved, a workman who does not need to be ashamed and who correctly handles the word of truth.",
    reference: "2 Timothy 2:15",
    book: "2 Timothy",
    chapter: 2,
    verse: 15
  },
  {
    date: "02-16",
    text: "May our Lord Jesus Christ himself and God our Father, who loved us and by his grace gave us eternal encouragement and good hope.",
    reference: "2 Thessalonians 2:16",
    book: "2 Thessalonians",
    chapter: 2,
    verse: 16
  },
  {
    date: "02-17",
    text: "The world and its desires pass away, but the man who does the will of God lives forever.",
    reference: "1 John 2:17",
    book: "1 John",
    chapter: 2,
    verse: 17
  },
  {
    date: "02-18",
    text: "Because he himself suffered when he was tempted, he is able to help those who are being tempted.",
    reference: "Hebrews 2:18",
    book: "Hebrews",
    chapter: 2,
    verse: 18
  },
  {
    date: "02-19",
    text: "Consequently, you are no longer foreigners and aliens, but fellow citizens with God's people and members of God's household.",
    reference: "Ephesians 2:19",
    book: "Ephesians",
    chapter: 2,
    verse: 19
  },
  {
    date: "02-20",
    text: "I have been crucified with Christ and I no longer live, but Christ lives in me. The life I live in the body, I live by faith in the Son of God, who loved me and gave himself for me.",
    reference: "Galatians 2:20",
    book: "Galatians",
    chapter: 2,
    verse: 20
  },
  {
    date: "02-21",
    text: "And everyone who calls on the name of the Lord will be saved.",
    reference: "Acts 2:21",
    book: "Acts",
    chapter: 2,
    verse: 21
  },
  {
    date: "02-22",
    text: "And in him you too are being built together to become a dwelling in which God lives by his Spirit.",
    reference: "Ephesians 2:22",
    book: "Ephesians",
    chapter: 2,
    verse: 22
  },
  {
    date: "02-23",
    text: "No one who denies the Son has the Father; whoever acknowledges the Son has the Father also.",
    reference: "1 John 2:23",
    book: "1 John",
    chapter: 2,
    verse: 23
  },
  {
    date: "02-24",
    text: "He himself bore our sins in his body on the tree, so that we might die to sins and live for righteousness; by his wounds you have been healed.",
    reference: "1 Peter 2:24",
    book: "1 Peter",
    chapter: 2,
    verse: 24
  },
  {
    date: "02-25",
    text: "For you were like sheep going astray, but now you have returned to the Shepherd and Overseer of your souls.",
    reference: "1 Peter 2:25",
    book: "1 Peter",
    chapter: 2,
    verse: 25
  },
  {
    date: "02-26",
    text: "To the man who pleases him, God gives wisdom, knowledge and happiness.",
    reference: "Ecclesiastes 2:26",
    book: "Ecclesiastes",
    chapter: 2,
    verse: 26
  },
  {
    date: "02-27",
    text: "As for you, the anointing you received from him remains in you, and you do not need anyone to teach you. But as his anointing teaches you about all things and as that anointing is real, not counterfeit just as it has taught you, remain in him.",
    reference: "1 John 2:27",
    book: "1 John",
    chapter: 2,
    verse: 27
  },
  {
    date: "02-28",
    text: "You have made known to me the paths of life; you will fill me with joy in your presence.",
    reference: "Acts 2:28",
    book: "Acts",
    chapter: 2,
    verse: 28
  },
  {
    date: "02-29",
    text: "If you know that he is righteous, you know that everyone who does what is right has been born of him.",
    reference: "1 John 2:29",
    book: "1 John",
    chapter: 2,
    verse: 29
  }
];