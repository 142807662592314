import { BirthVerse } from '../../types/verses';

export const mayVerses: BirthVerse[] = [
  {
    date: "05-01",
    text: "Therefore, since we have been justified through faith, we have peace with God through our Lord Jesus Christ.",
    reference: "Romans 5:1",
    book: "Romans",
    chapter: 5,
    verse: 1
  },
  {
    date: "05-02",
    text: "And live a life of love, just as Christ loved us and gave himself up for us as a fragrant offering and sacrifice to God.",
    reference: "Ephesians 5:2",
    book: "Ephesians",
    chapter: 5,
    verse: 2
  },
  {
    date: "05-03",
    text: "Blessed are the poor in spirit, for theirs is the kingdom of heaven.",
    reference: "Matthew 5:3",
    book: "Matthew",
    chapter: 5,
    verse: 3
  },
  {
    date: "05-04",
    text: "And when the Chief Shepherd appears, you will receive the crown of glory that will never fade away.",
    reference: "1 Peter 5:4",
    book: "1 Peter",
    chapter: 5,
    verse: 4
  },
  {
    date: "05-05",
    text: "Now it is God who has made us for this very purpose and has given us the Spirit as a deposit, guaranteeing what is to come.",
    reference: "2 Corinthians 5:5",
    book: "2 Corinthians",
    chapter: 5,
    verse: 5
  },
  {
    date: "05-06",
    text: "Blessed are those who hunger and thirst for righteousness, for they will be filled.",
    reference: "Matthew 5:6",
    book: "Matthew",
    chapter: 5,
    verse: 6
  },
  {
    date: "05-07",
    text: "Cast all your anxiety on him because he cares for you.",
    reference: "1 Peter 5:7",
    book: "1 Peter",
    chapter: 5,
    verse: 7
  },
  {
    date: "05-08",
    text: "But God demonstrates his own love for us in this: While we were still sinners, Christ died for us.",
    reference: "Romans 5:8",
    book: "Romans",
    chapter: 5,
    verse: 8
  },
  {
    date: "05-09",
    text: "Blessed are the peacemakers, for they will be called sons of God.",
    reference: "Matthew 5:9",
    book: "Matthew",
    chapter: 5,
    verse: 9
  },
  {
    date: "05-10",
    text: "And the God of all grace, who called you to his eternal glory in Christ, after you have suffered a little while, will himself restore you and make you strong, firm and steadfast.",
    reference: "1 Peter 5:10",
    book: "1 Peter",
    chapter: 5,
    verse: 10
  },
  {
    date: "05-11",
    text: "And this is the testimony: God has given us eternal life, and this life is in his Son.",
    reference: "1 John 5:11",
    book: "1 John",
    chapter: 5,
    verse: 11
  },
  {
    date: "05-12",
    text: "Rejoice and be glad, because great is your reward in heaven, for in the same way they persecuted the prophets who were before you.",
    reference: "Matthew 5:12",
    book: "Matthew",
    chapter: 5,
    verse: 12
  },
  {
    date: "05-13",
    text: "I write these things to you who believe in the name of the Son of God so that you may know that you have eternal life.",
    reference: "1 John 5:13",
    book: "1 John",
    chapter: 5,
    verse: 13
  },
  {
    date: "05-14",
    text: "This is the confidence we have in approaching God: that if we ask anything according to his will, he hears us.",
    reference: "1 John 5:14",
    book: "1 John",
    chapter: 5,
    verse: 14
  },
  {
    date: "05-15",
    text: "And if we know that he hears us whatever we ask we know that we have what we asked of him.",
    reference: "1 John 5:15",
    book: "1 John",
    chapter: 5,
    verse: 15
  },
  {
    date: "05-16",
    text: "In the same way, let your light shine before men, that they may see your good deeds and praise your Father in heaven.",
    reference: "Matthew 5:16",
    book: "Matthew",
    chapter: 5,
    verse: 16
  },
  {
    date: "05-17",
    text: "Therefore, if anyone is in Christ, he is a new creation; the old has gone, the new has come!",
    reference: "2 Corinthians 5:17",
    book: "2 Corinthians",
    chapter: 5,
    verse: 17
  },
  {
    date: "05-18",
    text: "give thanks in all circumstances, for this is God's will for you in Christ Jesus.",
    reference: "1 Thessalonians 5:18",
    book: "1 Thessalonians",
    chapter: 5,
    verse: 18
  },
  {
    date: "05-19",
    text: "Speak to one another with psalms, hymns and spiritual songs. Sing and make music in your heart to the Lord.",
    reference: "Ephesians 5:19",
    book: "Ephesians",
    chapter: 5,
    verse: 19
  },
  {
    date: "05-20",
    text: "We are therefore Christ's ambassadors, as though God were making his appeal through us. We implore you on Christ's behalf: Be reconciled to God.",
    reference: "2 Corinthians 5:20",
    book: "2 Corinthians",
    chapter: 5,
    verse: 20
  },
  {
    date: "05-21",
    text: "God made him who had no sin to be sin for us, so that in him we might become the righteousness of God.",
    reference: "2 Corinthians 5:21",
    book: "2 Corinthians",
    chapter: 5,
    verse: 21
  },
  {
    date: "05-22",
    text: "But the fruit of the Spirit is love, joy, peace, patience, kindness, goodness, faithfulness.",
    reference: "Galatians 5:22",
    book: "Galatians",
    chapter: 5,
    verse: 22
  },
  {
    date: "05-23",
    text: "May God himself, the God of peace, sanctify you through and through. May your whole spirit, soul and body be kept blameless at the coming of our Lord Jesus Christ.",
    reference: "1 Thessalonians 5:23",
    book: "1 Thessalonians",
    chapter: 5,
    verse: 23
  },
  {
    date: "05-24",
    text: "I tell you the truth, whoever hears my word and believes him who sent me has eternal life and will not be condemned; he has crossed over from death to life.",
    reference: "John 5:24",
    book: "John",
    chapter: 5,
    verse: 24
  },
  {
    date: "05-25",
    text: "Since we live by the Spirit, let us keep in step with the Spirit.",
    reference: "Galatians 5:25",
    book: "Galatians",
    chapter: 5,
    verse: 25
  },
  {
    date: "05-26",
    text: "Everyone was amazed and gave praise to God. They were filled with awe and said, We have seen remarkable things today.",
    reference: "Luke 5:26",
    book: "Luke",
    chapter: 5,
    verse: 26
  },
  {
    date: "05-27",
    text: "And he has given him authority to judge because he is the Son of Man.",
    reference: "John 5:27",
    book: "John",
    chapter: 5,
    verse: 27
  },
  {
    date: "05-28",
    text: "The grace of our Lord Jesus Christ be with you.",
    reference: "1 Thessalonians 5:28",
    book: "1 Thessalonians",
    chapter: 5,
    verse: 28
  },
  {
    date: "05-29",
    text: "Oh, that their hearts would be inclined to fear me and keep all my commands always, so that it might go well with them and their children forever!",
    reference: "Deuteronomy 5:29",
    book: "Deuteronomy",
    chapter: 5,
    verse: 29
  },
  {
    date: "05-30",
    text: "By myself I can do nothing; I judge only as I hear, and my judgment is just, for I seek not to please myself but him who sent me.",
    reference: "John 5:30",
    book: "John",
    chapter: 5,
    verse: 30
  },
  {
    date: "05-31",
    text: "God exalted him to his own right hand as Prince and Savior that he might give repentance and forgiveness of sins to Israel.",
    reference: "Acts 5:31",
    book: "Acts",
    chapter: 5,
    verse: 31
  }
];