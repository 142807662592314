import { BirthVerse } from '../types/verses';
import { januaryVerses } from '../data/verses/january';
import { februaryVerses } from '../data/verses/february';
import { marchVerses } from '../data/verses/march';
import { aprilVerses } from '../data/verses/april';
import { mayVerses } from '../data/verses/may';
import { juneVerses } from '../data/verses/june';
import { julyVerses } from '../data/verses/july';
import { augustVerses } from '../data/verses/august';
import { septemberVerses } from '../data/verses/september';
import { octoberVerses } from '../data/verses/october';
import { novemberVerses } from '../data/verses/november';
import { decemberVerses } from '../data/verses/december';

const allVerses = [
  ...januaryVerses,
  ...februaryVerses,
  ...marchVerses,
  ...aprilVerses,
  ...mayVerses,
  ...juneVerses,
  ...julyVerses,
  ...augustVerses,
  ...septemberVerses,
  ...octoberVerses,
  ...novemberVerses,
  ...decemberVerses
];

export function getBirthVerse(date: Date): BirthVerse {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const dateString = `${month}-${day}`;

  const verse = allVerses.find(v => v.date === dateString);
  if (!verse) {
    throw new Error(`No verse found for date: ${dateString}`);
  }

  return verse;
}