import { BirthVerse } from '../../types/verses';

export const septemberVerses: BirthVerse[] = [
  {
    date: "09-01",
    text: "I will praise you, O LORD, with all my heart; I will tell of all your wonders.",
    reference: "Psalm 9:1",
    book: "Psalm",
    chapter: 9,
    verse: 1
  },
  {
    date: "09-02",
    text: "I will be glad and rejoice in you; I will sing praise to your name, O Most High.",
    reference: "Psalm 9:2",
    book: "Psalm",
    chapter: 9,
    verse: 2
  },
  {
    date: "09-03",
    text: "The LORD said to him: I have heard the prayer and plea you have made before me; I have consecrated this temple, which you have built, by putting my Name there forever. My eyes and my heart will always be there.",
    reference: "1 Kings 9:3",
    book: "1 Kings",
    chapter: 9,
    verse: 3
  },
  {
    date: "09-04",
    text: "I prayed to the LORD my God and confessed: O Lord, the great and awesome God, who keeps his covenant of love with all who love him and obey his commands.",
    reference: "Daniel 9:4",
    book: "Daniel",
    chapter: 9,
    verse: 4
  },
  {
    date: "09-05",
    text: "Stand up and praise the LORD your God, who is from everlasting to everlasting. Blessed be your glorious name, and may it be exalted above all blessing and praise.",
    reference: "Nehemiah 9:5",
    book: "Nehemiah",
    chapter: 9,
    verse: 5
  },
  {
    date: "09-06",
    text: "For to us a child is born, to us a son is given, and the government will be on his shoulders. And he will be called Wonderful Counselor, Mighty God, Everlasting Father, Prince of Peace.",
    reference: "Isaiah 9:6",
    book: "Isaiah",
    chapter: 9,
    verse: 6
  },
  {
    date: "09-07",
    text: "Each man should give what he has decided in his heart to give, not reluctantly or under compulsion, for God loves a cheerful giver.",
    reference: "2 Corinthians 9:7",
    book: "2 Corinthians",
    chapter: 9,
    verse: 7
  },
  {
    date: "09-08",
    text: "And God is able to make all grace abound to you, so that in all things at all times, having all that you need, you will abound in every good work.",
    reference: "2 Corinthians 9:8",
    book: "2 Corinthians",
    chapter: 9,
    verse: 8
  },
  {
    date: "09-09",
    text: "The LORD is a refuge for the oppressed, a stronghold in times of trouble.",
    reference: "Psalm 9:9",
    book: "Psalm",
    chapter: 9,
    verse: 9
  },
  {
    date: "09-10",
    text: "Those who know your name will trust in you, for you, LORD, have never forsaken those who seek you.",
    reference: "Psalm 9:10",
    book: "Psalm",
    chapter: 9,
    verse: 10
  },
  {
    date: "09-11",
    text: "For through me your days will be many, and years will be added to your life.",
    reference: "Proverbs 9:11",
    book: "Proverbs",
    chapter: 9,
    verse: 11
  },
  {
    date: "09-12",
    text: "And God said, This is the sign of the covenant I am making between me and you and every living creature with you, a covenant for all generations to come.",
    reference: "Genesis 9:12",
    book: "Genesis",
    chapter: 9,
    verse: 12
  },
  {
    date: "09-13",
    text: "I have set my rainbow in the clouds, and it will be the sign of the covenant between me and the earth.",
    reference: "Genesis 9:13",
    book: "Genesis",
    chapter: 9,
    verse: 13
  },
  {
    date: "09-14",
    text: "How much more, then, will the blood of Christ, who through the eternal Spirit offered himself unblemished to God, cleanse our consciences from acts that lead to death, so that we may serve the living God!",
    reference: "Hebrews 9:14",
    book: "Hebrews",
    chapter: 9,
    verse: 14
  },
  {
    date: "09-15",
    text: "Thanks be to God for his indescribable gift!",
    reference: "2 Corinthians 9:15",
    book: "2 Corinthians",
    chapter: 9,
    verse: 15
  },
  {
    date: "09-16",
    text: "Whenever the rainbow appears in the clouds, I will see it and remember the everlasting covenant between God and all living creatures of every kind on the earth.",
    reference: "Genesis 9:16",
    book: "Genesis",
    chapter: 9,
    verse: 16
  },
  {
    date: "09-17",
    text: "But you are a forgiving God, gracious and compassionate, slow to anger and abounding in love.",
    reference: "Nehemiah 9:17",
    book: "Nehemiah",
    chapter: 9,
    verse: 17
  },
  {
    date: "09-18",
    text: "Immediately, something like scales fell from Saul's eyes, and he could see again. He got up and was baptized.",
    reference: "Acts 9:18",
    book: "Acts",
    chapter: 9,
    verse: 18
  },
  {
    date: "09-19",
    text: "Because of your great compassion you did not abandon them in the desert. By day the pillar of cloud did not cease to guide them on their path, nor the pillar of fire by night to shine on the way they were to take.",
    reference: "Nehemiah 9:19",
    book: "Nehemiah",
    chapter: 9,
    verse: 19
  },
  {
    date: "09-20",
    text: "But what about you? he asked. Who do you say I am? Peter answered, The Christ of God.",
    reference: "Luke 9:20",
    book: "Luke",
    chapter: 9,
    verse: 20
  },
  {
    date: "09-21",
    text: "She said to herself, If I only touch his cloak, I will be healed.",
    reference: "Matthew 9:21",
    book: "Matthew",
    chapter: 9,
    verse: 21
  },
  {
    date: "09-22",
    text: "And he said, The Son of Man must suffer many things and be rejected by the elders, chief priests and teachers of the law, and he must be killed and on the third day be raised to life.",
    reference: "Luke 9:22",
    book: "Luke",
    chapter: 9,
    verse: 22
  },
  {
    date: "09-23",
    text: "Then he said to them all: If any would come after me, he must deny himself and take up his cross daily and follow me.",
    reference: "Luke 9:23",
    book: "Luke",
    chapter: 9,
    verse: 23
  },
  {
    date: "09-24",
    text: "For whoever wants to save his life will lose it, but whoever loses his life for me will save it.",
    reference: "Luke 9:24",
    book: "Luke",
    chapter: 9,
    verse: 24
  },
  {
    date: "09-25",
    text: "Everyone who competes in the games goes into strict training. They do it to get a crown that will not last; but we do it to get a crown that will last forever.",
    reference: "1 Corinthians 9:25",
    book: "1 Corinthians",
    chapter: 9,
    verse: 25
  },
  {
    date: "09-26",
    text: "Therefore I do not run like a man running aimlessly; I do not fight like a man beating the air.",
    reference: "1 Corinthians 9:26",
    book: "1 Corinthians",
    chapter: 9,
    verse: 26
  },
  {
    date: "09-27",
    text: "So you handed them over to their enemies, who oppressed them. But when they were oppressed they cried out to you. From heaven you heard them, and in your great compassion you gave them deliverers, who rescued them from the hand of their enemies.",
    reference: "Nehemiah 9:27",
    book: "Nehemiah",
    chapter: 9,
    verse: 27
  },
  {
    date: "09-28",
    text: "So Christ was sacrificed once to take away the sins of many people; and he will appear a second time, not to bear sin, but to bring salvation to those who are waiting for him.",
    reference: "Hebrews 9:28",
    book: "Hebrews",
    chapter: 9,
    verse: 28
  },
  {
    date: "09-29",
    text: "Then he touched their eyes and said, According to your faith will it be done to you.",
    reference: "Matthew 9:29",
    book: "Matthew",
    chapter: 9,
    verse: 29
  },
  {
    date: "09-30",
    text: "The man answered, Now that is remarkable! You don't know where he comes from, yet he opened my eyes.",
    reference: "John 9:30",
    book: "John",
    chapter: 9,
    verse: 30
  }
];