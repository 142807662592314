import { BirthVerse } from '../../types/verses';

export const januaryVerses: BirthVerse[] = [
  {
    date: "01-01",
    text: "In the beginning was the Word, and the Word was with God, and the Word was God.",
    reference: "John 1:1",
    book: "John",
    chapter: 1,
    verse: 1
  },
  {
    date: "01-02",
    text: "a faith and knowledge resting on the hope of eternal life, which God, who does not lie, promised before the beginning of time.",
    reference: "Titus 1:2",
    book: "Titus",
    chapter: 1,
    verse: 2
  },
  {
    date: "01-03",
    text: "Praise be to the God and Father of our Lord Jesus Christ! In his great mercy he has given us new birth into a living hope through the resurrection of Jesus Christ from the dead.",
    reference: "1 Peter 1:3",
    book: "1 Peter",
    chapter: 1,
    verse: 3
  },
  {
    date: "01-04",
    text: "For he chose us in him before the creation of the world to be holy and blameless in his sight.",
    reference: "Ephesians 1:4",
    book: "Ephesians",
    chapter: 1,
    verse: 4
  },
  {
    date: "01-05",
    text: "Before I formed you in the womb I knew you, before you were born I set you apart; I appointed you as a prophet to the nations.",
    reference: "Jeremiah 1:5",
    book: "Jeremiah",
    chapter: 1,
    verse: 5
  },
  {
    date: "01-06",
    text: "being confident of this, that he who began a good work in you will carry it on to completion until the day of Christ Jesus.",
    reference: "Philippians 1:6",
    book: "Philippians",
    chapter: 1,
    verse: 6
  },
  {
    date: "01-07",
    text: "For God did not give us a spirit of timidity, but a spirit of power, of love and of self-discipline.",
    reference: "2 Timothy 1:7",
    book: "2 Timothy",
    chapter: 1,
    verse: 7
  },
  {
    date: "01-08",
    text: "He will keep you strong to the end, so that you will be blameless on the day of our Lord Jesus Christ.",
    reference: "1 Corinthians 1:8",
    book: "1 Corinthians",
    chapter: 1,
    verse: 8
  },
  {
    date: "01-09",
    text: "Be strong and courageous. Do not be terrified; do not be discouraged, for the LORD your God will be with you wherever you go.",
    reference: "Joshua 1:9",
    book: "Joshua",
    chapter: 1,
    verse: 9
  },
  {
    date: "01-10",
    text: "And we pray this in order that you may live a life worthy of the Lord and may please him in every way: bearing fruit in every good work, growing in the knowledge of God.",
    reference: "Colossians 1:10",
    book: "Colossians",
    chapter: 1,
    verse: 10
  },
  {
    date: "01-11",
    text: "and you will receive a rich welcome into the eternal kingdom of our Lord and Savior Jesus Christ.",
    reference: "2 Peter 1:11",
    book: "2 Peter",
    chapter: 1,
    verse: 11
  },
  {
    date: "01-12",
    text: "Blessed is the man who perseveres under trial, because when he has stood the test, he will receive the crown of life that God has promised to those who love him.",
    reference: "James 1:12",
    book: "James",
    chapter: 1,
    verse: 12
  },
  {
    date: "01-13",
    text: "And you also were included in Christ when you heard the word of truth, the gospel of your salvation. Having believed, you were marked in him with a seal, the promised Holy Spirit.",
    reference: "Ephesians 1:13",
    book: "Ephesians",
    chapter: 1,
    verse: 13
  },
  {
    date: "01-14",
    text: "He will be a joy and delight to you, and many will rejoice because of his birth.",
    reference: "Luke 1:14",
    book: "Luke",
    chapter: 1,
    verse: 14
  },
  {
    date: "01-15",
    text: "God, who set me apart from birth and called me by his grace, was pleased.",
    reference: "Galatians 1:15",
    book: "Galatians",
    chapter: 1,
    verse: 15
  },
  {
    date: "01-16",
    text: "I am not ashamed of the gospel, because it is the power of God for the salvation of everyone who believes.",
    reference: "Romans 1:16",
    book: "Romans",
    chapter: 1,
    verse: 16
  },
  {
    date: "01-17",
    text: "Every good and perfect gift is from above, coming down from the Father of the heavenly lights, who does not change like shifting shadows.",
    reference: "James 1:17",
    book: "James",
    chapter: 1,
    verse: 17
  },
  {
    date: "01-18",
    text: "He chose to give us birth through the word of truth, that we might be a kind of firstfruits of all he created.",
    reference: "James 1:18",
    book: "James",
    chapter: 1,
    verse: 18
  },
  {
    date: "01-19",
    text: "They will fight against you but will not overcome you, for I am with you and will rescue you, declares the LORD.",
    reference: "Jeremiah 1:19",
    book: "Jeremiah",
    chapter: 1,
    verse: 19
  },
  {
    date: "01-20",
    text: "He was chosen before the creation of the world, but was revealed in these last times for your sake.",
    reference: "1 Peter 1:20",
    book: "1 Peter",
    chapter: 1,
    verse: 20
  },
  {
    date: "01-21",
    text: "For to me, to live is Christ and to die is gain.",
    reference: "Philippians 1:21",
    book: "Philippians",
    chapter: 1,
    verse: 21
  },
  {
    date: "01-22",
    text: "set his seal of ownership on us, and put his Spirit in our hearts as a deposit, guaranteeing what is to come.",
    reference: "2 Corinthians 1:22",
    book: "2 Corinthians",
    chapter: 1,
    verse: 22
  },
  {
    date: "01-23",
    text: "For you have been born again, not of perishable seed, but of imperishable, through the living and enduring word of God.",
    reference: "1 Peter 1:23",
    book: "1 Peter",
    chapter: 1,
    verse: 23
  },
  {
    date: "01-24",
    text: "To him who is able to keep you from falling and to present you before his glorious presence without fault and with great joy.",
    reference: "Jude 1:24",
    book: "Jude",
    chapter: 1,
    verse: 24
  },
  {
    date: "01-25",
    text: "But the man who looks intently into the perfect law that gives freedom, and continues to do this, not forgetting what he has heard, but doing it he will be blessed in what he does.",
    reference: "James 1:25",
    book: "James",
    chapter: 1,
    verse: 25
  },
  {
    date: "01-26",
    text: "Then God said, Let us make man in our image, in our likeness, and let them rule over the fish of the sea and the birds of the air, over the livestock, over all the earth, and over all the creatures that move along the ground.",
    reference: "Genesis 1:26",
    book: "Genesis",
    chapter: 1,
    verse: 26
  },
  {
    date: "01-27",
    text: "The glorious riches of this mystery, which is Christ in you, the hope of glory.",
    reference: "Colossians 1:27",
    book: "Colossians",
    chapter: 1,
    verse: 27
  },
  {
    date: "01-28",
    text: "We proclaim him, admonishing and teaching everyone with all wisdom, so that we may present everyone perfect in Christ.",
    reference: "Colossians 1:28",
    book: "Colossians",
    chapter: 1,
    verse: 28
  },
  {
    date: "01-29",
    text: "To this end I labor, struggling with all his energy, which so powerfully works in me.",
    reference: "Colossians 1:29",
    book: "Colossians",
    chapter: 1,
    verse: 29
  },
  {
    date: "01-30",
    text: "It is because of him that you are in Christ Jesus, who has become for us wisdom from God that is, our righteousness, holiness and redemption.",
    reference: "1 Corinthians 1:30",
    book: "1 Corinthians",
    chapter: 1,
    verse: 30
  },
  {
    date: "01-31",
    text: "Therefore, as it is written: Let him who boasts boast in the Lord.",
    reference: "1 Corinthians 1:31",
    book: "1 Corinthians",
    chapter: 1,
    verse: 31
  }
];