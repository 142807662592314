import React, { useState, Suspense, lazy } from 'react';
import { Scroll } from 'lucide-react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import DatePicker from './components/DatePicker';
import VerseCard from './components/VerseCard';
import ShareButton from './components/ShareButton';
import Footer from './components/Footer';
import { getBirthVerse } from './utils/verses';

// Lazy load pages
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const TodaysVerse = lazy(() => import('./components/TodaysVerse'));

// Loading component
const Loading = () => (
  <div className="min-h-screen flex items-center justify-center bg-[#F8F7FF]">
    <div className="animate-pulse text-[#4F46E5]">Loading...</div>
  </div>
);

const App: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <ErrorBoundary>
      <Router>
        <div className="min-h-screen bg-[#F8F7FF]">
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={
                <>
                  <header className="bg-white shadow-sm">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                      <div className="flex justify-between items-center">
                        <Link
                          to="/"
                          className="flex items-center space-x-2 hover:opacity-80 transition-opacity"
                          aria-label="Return to homepage"
                        >
                          <Scroll className="h-6 w-6 text-[#4F46E5]" />
                          <h1 className="text-xl font-bold text-gray-900">BirthVerse</h1>
                        </Link>
                        <ShareButton />
                      </div>
                    </div>
                  </header>

                  <main className="max-w-3xl mx-auto px-4 py-12">
                    <div className="text-center mb-12">
                      <h2 className="text-4xl font-bold text-gray-900 mb-8">
                        Discover Your Divine Birthday Verse ✨
                      </h2>

                      <div className="space-y-6 text-gray-600">
                        <p className="text-xl">
                          Did you know there's a special Bible verse waiting just for you? 🌟
                        </p>

                        <p className="text-xl">
                          Every birthday holds a unique message from God's Word. 📖✨
                        </p>
                      </div>
                    </div>

                    <div className="mb-8">
                      <TodaysVerse />
                    </div>

                    <div className="bg-white rounded-2xl shadow-sm p-8 mb-8">
                      <DatePicker onDateSelect={handleDateSelect} />
                    </div>

                    {selectedDate && (
                      <div className="mt-8">
                        <VerseCard
                          verse={getBirthVerse(selectedDate)}
                          date={selectedDate}
                        />
                      </div>
                    )}
                  </main>
                  <Footer />
                </>
              } />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </Suspense>
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;