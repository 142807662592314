import { BirthVerse } from '../../types/verses';

export const juneVerses: BirthVerse[] = [
  {
    date: "06-01",
    text: "I saw the Lord seated on a throne, high and exalted, and the train of his robe filled the temple.",
    reference: "Isaiah 6:1",
    book: "Isaiah",
    chapter: 6,
    verse: 1
  },
  {
    date: "06-02",
    text: "Carry each other's burdens, and in this way you will fulfill the law of Christ.",
    reference: "Galatians 6:2",
    book: "Galatians",
    chapter: 6,
    verse: 2
  },
  {
    date: "06-03",
    text: "that it may go well with you and that you may enjoy long life on the earth.",
    reference: "Ephesians 6:3",
    book: "Ephesians",
    chapter: 6,
    verse: 3
  },
  {
    date: "06-04",
    text: "Turn, O LORD, and deliver me; save me because of your unfailing love.",
    reference: "Psalm 6:4",
    book: "Psalm",
    chapter: 6,
    verse: 4
  },
  {
    date: "06-05",
    text: "Love the LORD your God with all your heart and with all your soul and with all your strength.",
    reference: "Deuteronomy 6:5",
    book: "Deuteronomy",
    chapter: 6,
    verse: 5
  },
  {
    date: "06-06",
    text: "But godliness with contentment is great gain.",
    reference: "1 Timothy 6:6",
    book: "1 Timothy",
    chapter: 6,
    verse: 6
  },
  {
    date: "06-07",
    text: "Serve wholeheartedly, as if you were serving the Lord, not men.",
    reference: "Ephesians 6:7",
    book: "Ephesians",
    chapter: 6,
    verse: 7
  },
  {
    date: "06-08",
    text: "And what does the LORD require of you? To act justly and to love mercy and to walk humbly with your God.",
    reference: "Micah 6:8",
    book: "Micah",
    chapter: 6,
    verse: 8
  },
  {
    date: "06-09",
    text: "Let us not become weary in doing good, for at the proper time we will reap a harvest if we do not give up.",
    reference: "Galatians 6:9",
    book: "Galatians",
    chapter: 6,
    verse: 9
  },
  {
    date: "06-10",
    text: "Finally, be strong in the Lord and in his mighty power.",
    reference: "Ephesians 6:10",
    book: "Ephesians",
    chapter: 6,
    verse: 10
  },
  {
    date: "06-11",
    text: "Put on the full armor of God so that you can take your stand against the devil's schemes.",
    reference: "Ephesians 6:11",
    book: "Ephesians",
    chapter: 6,
    verse: 11
  },
  {
    date: "06-12",
    text: "Fight the good fight of the faith. Take hold of the eternal life to which you were called.",
    reference: "1 Timothy 6:12",
    book: "1 Timothy",
    chapter: 6,
    verse: 12
  },
  {
    date: "06-13",
    text: "Therefore put on the full armor of God, so that when the day of evil comes, you may be able to stand your ground, and after you have done everything, to stand.",
    reference: "Ephesians 6:13",
    book: "Ephesians",
    chapter: 6,
    verse: 13
  },
  {
    date: "06-14",
    text: "For sin shall not be your master, because you are not under law, but under grace.",
    reference: "Romans 6:14",
    book: "Romans",
    chapter: 6,
    verse: 14
  },
  {
    date: "06-15",
    text: "And with your feet fitted with the readiness that comes from the gospel of peace.",
    reference: "Ephesians 6:15",
    book: "Ephesians",
    chapter: 6,
    verse: 15
  },
  {
    date: "06-16",
    text: "Take up the shield of faith, with which you can extinguish all the flaming arrows of the evil one.",
    reference: "Ephesians 6:16",
    book: "Ephesians",
    chapter: 6,
    verse: 16
  },
  {
    date: "06-17",
    text: "But he who unites himself with the Lord is one with him in spirit.",
    reference: "1 Corinthians 6:17",
    book: "1 Corinthians",
    chapter: 6,
    verse: 17
  },
  {
    date: "06-18",
    text: "I will be a Father to you, and you will be my sons and daughters, says the Lord Almighty.",
    reference: "2 Corinthians 6:18",
    book: "2 Corinthians",
    chapter: 6,
    verse: 18
  },
  {
    date: "06-19",
    text: "Do you not know that your body is a temple of the Holy Spirit, who is in you, whom you have received from God? You are not your own.",
    reference: "1 Corinthians 6:19",
    book: "1 Corinthians",
    chapter: 6,
    verse: 19
  },
  {
    date: "06-20",
    text: "But store up for yourselves treasures in heaven, where moth and rust do not destroy, and where thieves do not break in and steal.",
    reference: "Matthew 6:20",
    book: "Matthew",
    chapter: 6,
    verse: 20
  },
  {
    date: "06-21",
    text: "For where your treasure is, there your heart will be also.",
    reference: "Matthew 6:21",
    book: "Matthew",
    chapter: 6,
    verse: 21
  },
  {
    date: "06-22",
    text: "But now that you have been set free from sin and have become slaves to God, the benefit you reap leads to holiness, and the result is eternal life.",
    reference: "Romans 6:22",
    book: "Romans",
    chapter: 6,
    verse: 22
  },
  {
    date: "06-23",
    text: "For the wages of sin is death, but the gift of God is eternal life in Christ Jesus our Lord.",
    reference: "Romans 6:23",
    book: "Romans",
    chapter: 6,
    verse: 23
  },
  {
    date: "06-24",
    text: "Grace to all who love our Lord Jesus Christ with an undying love.",
    reference: "Ephesians 6:24",
    book: "Ephesians",
    chapter: 6,
    verse: 24
  },
  {
    date: "06-25",
    text: "Therefore I tell you, do not worry about your life, what you will eat or drink; or about your body, what you will wear. Is not life more important than food, and the body more important than clothes?",
    reference: "Matthew 6:25",
    book: "Matthew",
    chapter: 6,
    verse: 25
  },
  {
    date: "06-26",
    text: "Look at the birds of the air; they do not sow or reap or store away in barns, and yet your heavenly Father feeds them. Are you not much more valuable than they?",
    reference: "Matthew 6:26",
    book: "Matthew",
    chapter: 6,
    verse: 26
  },
  {
    date: "06-27",
    text: "He rescues and he saves; he performs signs and wonders in the heavens and on the earth. He has rescued Daniel from the power of the lions.",
    reference: "Daniel 6:27",
    book: "Daniel",
    chapter: 6,
    verse: 27
  },
  {
    date: "06-28",
    text: "Bless those who curse you, pray for those who mistreat you.",
    reference: "Luke 6:28",
    book: "Luke",
    chapter: 6,
    verse: 28
  },
  {
    date: "06-29",
    text: "Jesus answered, The work of God is this: to believe in the one he has sent.",
    reference: "John 6:29",
    book: "John",
    chapter: 6,
    verse: 29
  },
  {
    date: "06-30",
    text: "If that is how God clothes the grass of the field, which is here today and tomorrow is thrown into the fire, will he not much more clothe you, O you of little faith?",
    reference: "Matthew 6:30",
    book: "Matthew",
    chapter: 6,
    verse: 30
  }
];