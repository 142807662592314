import { BirthVerse } from '../../types/verses';

export const decemberVerses: BirthVerse[] = [
  {
    date: "12-01",
    text: "Therefore, since we are surrounded by such a great cloud of witnesses, let us throw off everything that hinders and the sin that so easily entangles, and let us run with perseverance the race marked out for us.",
    reference: "Hebrews 12:1",
    book: "Hebrews",
    chapter: 12,
    verse: 1
  },
  {
    date: "12-02",
    text: "Let us fix our eyes on Jesus, the author and perfecter of our faith, who for the joy set before him endured the cross, scorning its shame, and sat down at the right hand of the throne of God.",
    reference: "Hebrews 12:2",
    book: "Hebrews",
    chapter: 12,
    verse: 2
  },
  {
    date: "12-03",
    text: "For by the grace given me I say to every one of you: Do not think of yourself more highly than you ought, but rather think of yourself with sober judgment, in accordance with the measure of faith God has given you.",
    reference: "Romans 12:3",
    book: "Romans",
    chapter: 12,
    verse: 3
  },
  {
    date: "12-04",
    text: "In that day you will say: Give thanks to the LORD, call on his name; make known among the nations what he has done, and proclaim that his name is exalted.",
    reference: "Isaiah 12:4",
    book: "Isaiah",
    chapter: 12,
    verse: 4
  },
  {
    date: "12-05",
    text: "Sing to the LORD, for he has done glorious things; let this be known to all the world.",
    reference: "Isaiah 12:5",
    book: "Isaiah",
    chapter: 12,
    verse: 5
  },
  {
    date: "12-06",
    text: "There are different kinds of working, but the same God works all of them in all men.",
    reference: "1 Corinthians 12:6",
    book: "1 Corinthians",
    chapter: 12,
    verse: 6
  },
  {
    date: "12-07",
    text: "Indeed, the very hairs of your head are all numbered. Don't be afraid; you are worth more than many sparrows.",
    reference: "Luke 12:7",
    book: "Luke",
    chapter: 12,
    verse: 7
  },
  {
    date: "12-08",
    text: "To one there is given through the Spirit the message of wisdom, to another the message of knowledge by means of the same Spirit.",
    reference: "1 Corinthians 12:8",
    book: "1 Corinthians",
    chapter: 12,
    verse: 8
  },
  {
    date: "12-09",
    text: "But he said to me, My grace is sufficient for you, for my power is made perfect in weakness. Therefore I will boast all the more gladly about my weaknesses, so that Christ's power may rest on me.",
    reference: "2 Corinthians 12:9",
    book: "2 Corinthians",
    chapter: 12,
    verse: 9
  },
  {
    date: "12-10",
    text: "In his hand is the life of every creature and the breath of all mankind.",
    reference: "Job 12:10",
    book: "Job",
    chapter: 12,
    verse: 10
  },
  {
    date: "12-11",
    text: "Never be lacking in zeal, but keep your spiritual fervor, serving the Lord.",
    reference: "Romans 12:11",
    book: "Romans",
    chapter: 12,
    verse: 11
  },
  {
    date: "12-12",
    text: "Be joyful in hope, patient in affliction, faithful in prayer.",
    reference: "Romans 12:12",
    book: "Romans",
    chapter: 12,
    verse: 12
  },
  {
    date: "12-13",
    text: "Now all has been heard; here is the conclusion of the matter: Fear God and keep his commandments, for this is the whole duty of man.",
    reference: "Ecclesiastes 12:13",
    book: "Ecclesiastes",
    chapter: 12,
    verse: 13
  },
  {
    date: "12-14",
    text: "If you fear the LORD and serve and obey him and do not rebel against his commands, and if both you and the king who reigns over you follow the LORD your God good!",
    reference: "1 Samuel 12:14",
    book: "1 Samuel",
    chapter: 12,
    verse: 14
  },
  {
    date: "12-15",
    text: "See to it that no one misses the grace of God and that no bitter root grows up to cause trouble and defile many.",
    reference: "Hebrews 12:15",
    book: "Hebrews",
    chapter: 12,
    verse: 15
  },
  {
    date: "12-16",
    text: "Live in harmony with one another. Do not be proud, but be willing to associate with people of low position. Do not be conceited.",
    reference: "Romans 12:16",
    book: "Romans",
    chapter: 12,
    verse: 16
  },
  {
    date: "12-17",
    text: "Do not repay anyone evil for evil. Be careful to do what is right in the eyes of everybody.",
    reference: "Romans 12:17",
    book: "Romans",
    chapter: 12,
    verse: 17
  },
  {
    date: "12-18",
    text: "Here is my servant whom I have chosen, the one I love, in whom I delight; I will put my Spirit on him, and he will proclaim justice to the nations.",
    reference: "Matthew 12:18",
    book: "Matthew",
    chapter: 12,
    verse: 18
  },
  {
    date: "12-19",
    text: "Do not take revenge, my friends, but leave room for God's wrath, for it is written: It is mine to avenge; I will repay, says the Lord.",
    reference: "Romans 12:19",
    book: "Romans",
    chapter: 12,
    verse: 19
  },
  {
    date: "12-20",
    text: "There is deceit in the hearts of those who plot evil, but joy for those who promote peace.",
    reference: "Proverbs 12:20",
    book: "Proverbs",
    chapter: 12,
    verse: 20
  },
  {
    date: "12-21",
    text: "Do not be overcome by evil, but overcome evil with good.",
    reference: "Romans 12:21",
    book: "Romans",
    chapter: 12,
    verse: 21
  },
  {
    date: "12-22",
    text: "For the sake of his great name the LORD will not reject his people, because the LORD was pleased to make you his own.",
    reference: "1 Samuel 12:22",
    book: "1 Samuel",
    chapter: 12,
    verse: 22
  },
  {
    date: "12-23",
    text: "A prudent man keeps his knowledge to himself, but the heart of fools blurts out folly.",
    reference: "Proverbs 12:23",
    book: "Proverbs",
    chapter: 12,
    verse: 23
  },
  {
    date: "12-24",
    text: "But be sure to fear the LORD and serve him faithfully with all your heart; consider what great things he has done for you.",
    reference: "1 Samuel 12:24",
    book: "1 Samuel",
    chapter: 12,
    verse: 24
  },
  {
    date: "12-25",
    text: "An anxious heart weighs a man down, but a kind word cheers him up.",
    reference: "Proverbs 12:25",
    book: "Proverbs",
    chapter: 12,
    verse: 25
  },
  {
    date: "12-26",
    text: "Whoever serves me must follow me; and where I am, my servant also will be. My Father will honor the one who serves me.",
    reference: "John 12:26",
    book: "John",
    chapter: 12,
    verse: 26
  },
  {
    date: "12-27",
    text: "Now you are the body of Christ, and each one of you is a part of it.",
    reference: "1 Corinthians 12:27",
    book: "1 Corinthians",
    chapter: 12,
    verse: 27
  },
  {
    date: "12-28",
    text: "Therefore, since we are receiving a kingdom that cannot be shaken, let us be thankful, and so worship God acceptably with reverence and awe.",
    reference: "Hebrews 12:28",
    book: "Hebrews",
    chapter: 12,
    verse: 28
  },
  {
    date: "12-29",
    text: "The most important one, answered Jesus, is this: Hear, O Israel, the Lord our God, the Lord is one.",
    reference: "Mark 12:29",
    book: "Mark",
    chapter: 12,
    verse: 29
  },
  {
    date: "12-30",
    text: "Love the Lord your God with all your heart and with all your soul and with all your mind and with all your strength.",
    reference: "Mark 12:30",
    book: "Mark",
    chapter: 12,
    verse: 30
  },
  {
    date: "12-31",
    text: "But seek his kingdom, and these things will be given to you as well.",
    reference: "Luke 12:31",
    book: "Luke",
    chapter: 12,
    verse: 31
  }
];