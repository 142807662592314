import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { initErrorTracking } from './utils/errorTracking';
import { initAnalytics } from './utils/analytics';

// Initialize analytics and error tracking in production
if (process.env.NODE_ENV === 'production') {
  // Initialize GTM dataLayer
  initAnalytics();

  // Initialize error tracking
  try {
    initErrorTracking();
  } catch (err) {
    console.error('Failed to initialize error tracking:', err);
  }
}

const root = document.getElementById('root');
if (!root) {
  throw new Error('Root element not found');
}

try {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} catch (error) {
  console.error('Failed to render app:', error);
}

// Register service worker for offline support
serviceWorker.register();